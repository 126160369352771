import { useQuery } from "@tanstack/react-query";

import { API_ENDPOINT } from "../../constants";
import { ApiBaseResponse } from "../../types/ApiBaseResponse";
import { Meta } from "../../types/Meta";
import { requestJson } from "../api";
import { metaKeys } from "./metaKeys";

type HookOptions = {
  enabled?: boolean;
};

export const useGetMeta = (options: HookOptions = {}) => {
  const query = useQuery(
    metaKeys.all,
    () => requestJson<ApiBaseResponse<Meta>>(`${API_ENDPOINT}/app/meta`).then(({ data }) => data),
    {
      enabled: options.enabled,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 1000 * 60 * 5,
    }
  );

  return query;
};
