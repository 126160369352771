import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { mockMetaFactory } from "../mockdata/meta";

export const metaHandlers = [
  rest.get(`${API_ENDPOINT}/app/meta`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockMetaFactory.meta.getAll()[0],
      })
    );
  }),
];
