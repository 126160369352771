import * as polished from "polished";
import { useMeta } from "../../../context/MetaContext";

export const MAX_COLORS = 3;
const MAX_SHADES = Math.floor(MAX_COLORS / 2);

export function useEducationColors() {
  const { education_levels } = useMeta();
  const colors = education_levels.reduce(
    (acc, level) => {
      return {
        ...acc,
        [level.label.toLowerCase()]: level.color,
      };
    },
    {} as Record<string, string>
  );

  // add fallback color
  colors["fallback"] = "#ec6e00"; // --color-primary-600:

  const colorShades = Object.entries(colors).reduce(
    (acc, [colorName, color]) => {
      const shades = [];
      for (let i = 0; i < MAX_COLORS; i++) {
        if (i < MAX_SHADES) {
          const tintedColor = polished.tint(0.4, color);
          shades.unshift(tintedColor);
        } else if (i === MAX_SHADES) {
          shades.push(color);
        } else {
          const shadedColor = polished.shade(0.15, color);
          shades.push(shadedColor);
        }
      }

      return {
        ...acc,
        [colorName]: shades.reduce(
          (acc, shade, i) => ({
            ...acc,
            [`${i + 1}`]: shade,
          }),
          {} as Record<string, string>
        ),
      };
    },
    {} as Record<string, Record<string, string>>
  );

  const renderCSSVariables = () => {
    return Object.entries(colorShades)
      .map(([color, shades]) => {
        return Object.entries(shades)
          .flatMap(([shade, value], i) => {
            // note: manual changing readableColor output black (000) to design system (333)
            // https://github.com/styled-components/polished/issues/615
            let readableColor = polished.readableColor(value);
            if (readableColor === "#000") {
              readableColor = "#333";
            }

            const classes = [`--bg-${color}-${shade}: ${value};`, `--text-${color}-${shade}: ${readableColor};`];

            if (i === MAX_SHADES) {
              classes.push(`--bg-${color}-base: ${value};`);
              classes.push(`--text-${color}-base: ${readableColor};`);
            }

            // add hover variants
            if (i < MAX_SHADES) {
              classes.push(`--bg-${color}-${shade}-hover: ${polished.mix(0.5, value, "#fff")};`);
            } else {
              classes.push(`--bg-${color}-${shade}-hover: ${polished.mix(0.5, value, "#000")};`);
            }

            return classes;
          })
          .join("\n");
      })
      .join("\n");
  };

  return {
    colors,
    colorShades,
    renderCSSVariables,
  };
}
