import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { assessmentRoundDataGoals, mockAssessmentRoundFactory } from "../mockdata/assessmentRounds";

export const assessmentRoundsHandlers = [
  rest.get(`${API_ENDPOINT}/app/assessment-rounds`, (req, res, ctx) => {
    const data = mockAssessmentRoundFactory.assessmentRound.getAll();

    return res(
      ctx.status(200),
      ctx.json({
        data: data,
        meta: {
          current_page: 1,
          from: 1,
          last_page: 1,
          links: [],
          path: "",
          per_page: 15,
          to: data.length,
          total: data.length,
        },
      })
    );
  }),

  // not loaded in mockData because it is an array and could not define that.. TODO
  rest.get(`${API_ENDPOINT}/app/assessment-rounds/:id/goals`, (req, res, ctx) => {
    const { id } = req.params;
    const data = assessmentRoundDataGoals()[id as string];

    return res(
      ctx.status(200),
      ctx.json({
        data: data || [],
      })
    );
  }),
];
