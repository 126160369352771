import { PropsWithChildren } from "react";
import { Toaster } from "sonner";
import styles from "./ToastArea.module.scss";

export const ToastArea: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Toaster
        className={styles.toast__area}
        position="bottom-right"
        duration={10000}
        visibleToasts={20}
        offset={0}
        expand
      />
      {children}
    </>
  );
};
