import { Auth } from "aws-amplify";
import QueryString from "qs";
import { getApiAccessToken } from "../auth/AwsAmplifyAuthenticationProvider";
import { ApiErrorResponse } from "../types/ApiErrorResponse";
import { showToast } from "./toast/toast";

interface Init extends RequestInit {
  withAuth?: boolean;
  queryParams?: Record<any, any>;
}

interface RequestJsonInit extends Omit<Init, "body"> {
  body?: any;
}

// @ts-ignore
export class ErrorResponse extends Error {
  public isApiError = true;

  constructor(
    message: string,
    public status: number,
    public cause: ApiErrorResponse
  ) {
    super(message);
  }
}

/**
 * To opt out of authenticated requests, use `request(uri, { withAuth: false })` to make unauthenticated requests, or
 * use request(uri, { headers: { authorization: "..." } }) to overide.
 */
export const request = async (url: string, { withAuth, queryParams, ...init }: Init = {}) => {
  const accessToken = getApiAccessToken();
  const authHeaders: HeadersInit =
    withAuth !== false && accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {};

  const formattedUrl = queryParams ? `${url}?${QueryString.stringify(queryParams, { arrayFormat: "brackets" })}` : url;

  return fetch(formattedUrl, {
    ...init,
    headers: {
      ...authHeaders,
      ...init.headers,
    },
  });
};

export const requestJson = async <T = any,>(url: string, init?: RequestJsonInit): Promise<T> => {
  const res = await request(url, {
    ...init,
    headers: {
      accept: "application/json",
      ...init?.headers,
    },
    body: init?.body ? (typeof init.body === "string" ? init.body : JSON.stringify(init.body)) : undefined,
  });

  if (res.status === 401) {
    // Unauthorized
    return {} as T;
  }

  if (res.status === 403) {
    // Forbidden
    throw new ErrorResponse("Forbidden", res.status, {
      message: "Geen toegang",
      status: res.status,
      errors: {
        message: ["Geen toegang"],
      },
    });
  }

  if (!res.ok) {
    // safely parse json
    const json = await res.json().catch(() => ({
      errors: [
        {
          message: "Server fout",
        },
      ],
    }));

    throw new ErrorResponse(res.statusText, res.status, json);
  }
  // 204 No Content
  if (res.status === 204) {
    return {} as T;
  }

  const json: T = await res.json();
  return json;
};

type HandleApiErrorOptions = {
  msg?: string;
  onAfter?: (errors: Record<string, string[]>) => void;
};
export const handleApiError =
  ({ msg, onAfter }: HandleApiErrorOptions = {}) =>
  async (errorRes: ErrorResponse) => {
    const cause = errorRes.cause;
    const user = await Auth.currentAuthenticatedUser();

    if (!user) {
      // Last resort, refresh session or redirect to login
      const session = await Auth.currentSession();
      if (!session.isValid()) {
        await Auth.signOut();
      }
      return;
    }

    if (cause?.errors) {
      showToast({
        type: "error",
        message: msg ?? cause.message ?? "Server fout",
      });
      return;
    }
    if (onAfter) {
      onAfter(cause.errors);
    }
  };
