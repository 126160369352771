import { factory, primaryKey } from "@mswjs/data";
import { ReportResponse } from "../../types/Report";
import { ReportFilterResponse } from "../../types/ReportFilter";
import { getRandomMetaItems } from "./reportGraph";

export const mockReportData: ReportResponse = {
  data: [
    {
      id: "1",
      title: "Samantha Kane",
      type: "student",
      filter_id: "student_id",
      education_level: {
        id: 1,
        label: "Groen",
        level: 1,
        color: "hsla(102, 52%, 55%, 1)",
      },
      datapoints: [
        {
          // note: M starts at 0, so 9 is October
          date: new Date(2023, 9, 1).toISOString(),
          progress_percentage: 5,
          meta: { items: getRandomMetaItems(5) },
        },
        {
          date: new Date(2023, 10, 1).toISOString(),
          progress_percentage: 14,
          meta: { items: getRandomMetaItems(14) },
        },
        {
          date: new Date(2023, 11, 15).toISOString(),
          progress_percentage: 20,
          meta: { items: getRandomMetaItems(20) },
        },
        {
          date: new Date(2023, 12, 1).toISOString(),
          progress_percentage: 20,
          meta: { items: getRandomMetaItems(20) },
        },
        {
          date: new Date(2024, 2, 15).toISOString(),
          progress_percentage: 40,
          meta: { items: getRandomMetaItems(40) },
        },
      ],
    },
    {
      id: "2",
      title: "Haley Suarez",
      type: "student",
      filter_id: "student_id",
      education_level: {
        id: 2,
        label: "Geel",
        level: 2,
        color: "hsla(102, 52%, 55%, 1)",
      },
      datapoints: [
        {
          date: new Date(2023, 9, 1).toISOString(),
          progress_percentage: 15,
          meta: { items: getRandomMetaItems(15) },
        },
        {
          date: new Date(2023, 10, 1).toISOString(),
          progress_percentage: 14,
          meta: { items: getRandomMetaItems(14) },
        },
        {
          date: new Date(2023, 11, 15).toISOString(),
          progress_percentage: 60,
          meta: { items: getRandomMetaItems(60) },
        },
        {
          date: new Date(2023, 12, 1).toISOString(),
          progress_percentage: 80,
          meta: { items: getRandomMetaItems(80) },
        },
        {
          date: new Date(2024, 2, 15).toISOString(),
          progress_percentage: 85,
          meta: { items: getRandomMetaItems(85) },
        },
      ],
    },
    {
      id: "3",
      title: "Jacqueline Schmitt",
      type: "student",
      filter_id: "student_id",
      education_level: {
        id: 3,
        label: "Paars",
        level: 3,
        color: "hsla(102, 52%, 55%, 1)",
      },
      datapoints: [
        {
          date: new Date(2023, 9, 1).toISOString(),
          progress_percentage: 0,
        },
        {
          date: new Date(2023, 10, 1).toISOString(),
          progress_percentage: 50,
        },
        {
          date: new Date(2023, 11, 15).toISOString(),
          progress_percentage: 55,
        },
        {
          date: new Date(2023, 12, 1).toISOString(),
          progress_percentage: 80,
        },
        {
          date: new Date(2024, 2, 15).toISOString(),
          progress_percentage: 80,
        },
      ],
    },
  ],
  averages: [
    {
      title: "Gemiddelde",
      datapoints: [
        {
          date: new Date(2023, 9, 1).toISOString(),
          progress_percentage: 7,
        },
        {
          date: new Date(2023, 10, 1).toISOString(),
          progress_percentage: 26,
        },
        {
          date: new Date(2023, 11, 15).toISOString(),
          progress_percentage: 45,
        },
        {
          date: new Date(2023, 12, 1).toISOString(),
          progress_percentage: 60,
        },
        {
          date: new Date(2024, 2, 15).toISOString(),
          progress_percentage: 68,
        },
      ],
    },
  ],
  swimlanes: [
    {
      title: "Leerjaar 1",
      progress_percentage: 0,
    },
    {
      title: "Leerjaar 2",
      progress_percentage: 40,
    },
  ],
  bandwidth_bg: [
    {
      date: new Date(2023, 9, 1).toISOString(),
      progress_percentage: 0,
      margin: 30,
    },
    {
      date: new Date(2024, 2, 15).toISOString(),
      progress_percentage: 100,
      margin: 30,
    },
  ],
  bandwidth_fg: [
    {
      date: new Date(2023, 9, 1).toISOString(),
      progress_percentage: 0,
      margin: 5,
    },
    {
      date: new Date(2024, 2, 15).toISOString(),
      progress_percentage: 100,
      margin: 5,
    },
  ],
};

export const mockReportFiltersData: ReportFilterResponse = [
  {
    field_id: "subject_id",
    field_label: "Vak",
    field_control: "select",
    field_required: true,
    field_options: [
      {
        label: "Geschiedenis",
        value: "5",
      },
      {
        label: "Frans",
        value: "8",
      },
      {
        label: "Wiskunde",
        value: "9",
      },
      {
        label: "Nederlands",
        value: "10",
      },
      {
        label: "Engels",
        value: "11",
      },
      {
        label: "Biologie",
        value: "12",
      },
      {
        label: "Aardrijkskunde",
        value: "13",
      },
      {
        label: "Alle vakken",
        value: "all",
      },
    ],
  },
  {
    field_id: "class_id",
    field_label: "Klas",
    field_control: "select",
    field_required: true,
    field_options: [
      {
        label: "MA1A",
        value: "1",
      },
      {
        label: "MA1B",
        value: "2",
      },
      {
        label: "MA1C",
        value: "3",
      },
      {
        label: "MA1D",
        value: "4",
      },
      {
        label: "Alle klassen",
        value: "all",
      },
    ],
  },
  {
    field_id: "cohort_id",
    field_label: "Cohort",
    field_control: "select",
    field_required: true,
    field_options: [
      {
        label: "2021-2022",
        value: "1",
      },
      {
        label: "2022-2023",
        value: "2",
      },
      {
        label: "2023-2024",
        value: "3",
      },
    ],
  },
  {
    field_id: "student_id",
    field_label: "Student",
    field_control: "multiselect",
    field_required: true,
    field_options: [
      {
        label: "Sander Wessel",
        value: "1",
      },
      {
        label: "John Doe",
        value: "2",
      },
      {
        label: "Luca Faser",
        value: "3",
      },
      {
        label: "Alle studenten",
        value: "all",
      },
    ],
  },
  {
    field_id: "daterange",
    field_label: "Periode",
    field_control: "date",
    field_from: "2021-09-01",
    field_to: "2024-02-01",
    field_required: false,
    field_options: [
      {
        label: "Leerjaar 1",
        value: "2021-09-01,2022-08-31",
      },
      {
        label: "Leerjaar 2",
        value: "2022-09-01,2023-08-31",
      },
      {
        label: "Leerjaar 3",
        value: "2023-09-01,2024-08-31",
      },
    ],
  },
  {
    field_id: "outliers",
    field_label: "Uitschieters",
    field_control: "select",
    field_required: false,
    field_options: [
      {
        label: "Tonen",
        value: "true",
      },
      {
        label: "Verbergen",
        value: "false",
      },
    ],
  },
  {
    field_id: "teacher_id",
    field_label: "Docent",
    field_control: "multiselect",
    field_required: true,
    field_options: [
      {
        label: "Johan",
        value: "1",
      },
      {
        label: "Sander",
        value: "2",
      },
      {
        label: "Alle docenten",
        value: "all",
      },
    ],
  },
];

export const mockReportFactory = factory({
  filters: {
    field_id: primaryKey(String),
    field_label: String,
    field_control: String,
    field_from: String,
    field_to: String,
    field_disabled: Boolean,
    field_required: Boolean,
    field_options: Array,
  },
});
