import { SVGProps } from "react";
const SvgAddCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path d="M4 7.429h-.008a.571.571 0 1 0 0 1.143H12.008a.571.571 0 1 0 0-1.143H4Z" fill="currentColor" />
    <path d="M8.572 4v-.008a.571.571 0 1 0-1.143 0V12.008a.571.571 0 1 0 1.143 0V4Z" fill="currentColor" />
    <path
      d="M8 0C3.59 0 .001 3.589.001 8c0 4.41 3.589 7.999 8 7.999 4.41 0 7.999-3.589 7.999-8C16 3.59 12.41 0 8 0Zm0 1.143A6.848 6.848 0 0 1 14.857 8 6.848 6.848 0 0 1 8 14.857 6.848 6.848 0 0 1 1.143 8 6.848 6.848 0 0 1 8 1.143Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAddCircle;
