import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { ReportRouteKey } from "../../types/Report";
import { ReportFilterId } from "../../types/ReportFilter";
import { mockReportData, mockReportFactory } from "../mockdata/report";
import { mockReportDataRandomized } from "../mockdata/reportGraph";

export const reportHandlers = [
  rest.get(`${API_ENDPOINT}/app/reports/:reportType`, (req, res, ctx) => {
    // handle query params for filter[subject_id]=1
    const queryParams = req.url.searchParams;
    // if required fields are missing return validation error
    const filtersByReportType = findFiltersByReportType(req.params.reportType as ReportRouteKey);
    const requiredFields = filtersByReportType.filter((filter) => filter.field_required);
    const requiredFieldsMissing = requiredFields.filter((filter) => !queryParams.get(`filter[${filter.field_id}]`));
    if (requiredFieldsMissing.length > 0) {
      return res(
        ctx.status(422),
        ctx.delay(300),
        ctx.json({
          errors: requiredFieldsMissing.reduce(
            (acc, filter) => ({
              ...acc,
              [filter.field_id]: `${filter.field_label} is verplicht`,
            }),
            {}
          ),
        })
      );
    }

    return res(
      ctx.status(200),
      ctx.delay(6000),
      ctx.json({
        data: mockReportDataRandomized(mockReportData),
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/reports/:reportType/filters`, (req, res, ctx) => {
    // handle query params for filter[subject_id]=1
    const queryParams = req.url.searchParams;

    // if there is a filter query param, update the selected filters
    if ([...queryParams.keys()].find((key) => key.includes("filter"))) {
      updateSelectedFilters(queryParams);
    }
    // if there is no subject_id filter, disable the cohort_id filter
    updateDisabledFilters(queryParams, "subject_id", "cohort_id");

    return res(
      ctx.status(200),
      ctx.delay(3000),
      ctx.json({
        data: findFiltersByReportType(req.params.reportType as ReportRouteKey),
      })
    );
  }),
];

function findFiltersByReportType(reportType: ReportRouteKey) {
  if (reportType === "leerlingen") {
    return mockReportFactory.filters.findMany({
      where: {
        field_id: {
          in: ["subject_id", "cohort_id", "daterange"],
        },
      },
    });
  }
  if (reportType === "docenten") {
    return mockReportFactory.filters.findMany({
      where: {
        field_id: {
          in: ["teacher_id", "daterange"],
        },
      },
    });
  }
  if (reportType === "lesgroep") {
    return mockReportFactory.filters.findMany({
      where: {
        field_id: {
          in: ["cohort_id", "subject_id", "daterange"],
        },
      },
    });
  }

  if (reportType === "klassen") {
    return mockReportFactory.filters.findMany({
      where: {
        field_id: {
          in: ["class_id", "daterange"],
        },
      },
    });
  }

  return [];
}

function updateDisabledFilters(queryParams: URLSearchParams, fieldDepence: string, fieldTarget: string) {
  if (!queryParams.get(`filter[${fieldDepence}]`)) {
    mockReportFactory.filters.update({
      where: {
        field_id: {
          equals: fieldTarget,
        },
      },
      data: {
        field_disabled: true,
      },
    });
  } else {
    mockReportFactory.filters.update({
      where: {
        field_id: {
          equals: fieldTarget,
        },
      },
      data: {
        field_disabled: false,
      },
    });
  }
}

function updateSelectedFilters(queryParams: URLSearchParams) {
  const filterFields: ReportFilterId[] = [
    "class_id",
    "cohort_id",
    "student_id",
    "subject_id",
    "teacher_id",
    "daterange",
  ];
  filterFields.forEach((fieldId) => {
    let filterValues = queryParams.getAll(`filter[${fieldId}]`);

    if (filterValues.length === 0) {
      filterValues = queryParams.getAll(`filter[${fieldId}][]`);
    }

    if (filterValues) {
      updateSelectedFilter(fieldId, filterValues);
    }
  });
}

function updateSelectedFilter(fieldId: ReportFilterId, filterValues: string[]) {
  const filter = mockReportFactory.filters.findFirst({
    where: {
      field_id: {
        equals: fieldId,
      },
    },
  });
  const updateFilterOptions = filter?.field_options.map((option: any) => {
    let selected = filterValues.includes(String(option.value));

    // // if filter[daterange]=2021-09-01,2022-08-31 equals the option value, set selected to true
    if (filter.field_control === "date") {
      selected = filterValues.join(",") === option.value;
    }

    return {
      ...option,
      selected,
    };
  });
  mockReportFactory.filters.update({
    where: {
      field_id: {
        equals: fieldId,
      },
    },
    // set field_options to have property selected to be true for the selected subject_ids
    data: {
      field_options: updateFilterOptions,
    },
  });
}
