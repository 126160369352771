import { FC } from "react";
import * as icons from "./";
import { IconProps } from "./Icon.types";

export const Icon: FC<IconProps> = ({ name }) => {
  const lowerName = name?.toString().toLowerCase();
  const iconList: { [key: string]: any } = {};
  Object.entries(icons).forEach(([icon, IconComponent]) => {
    const id = icon.toLowerCase();
    iconList[id] = IconComponent;
  });

  if (lowerName && iconList[lowerName]) {
    return iconList[lowerName]();
  }
};
