import { SVGProps } from "react";
const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="b"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="currentColor" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#b)">
      <path
        d="M1.667 16.667a.804.804 0 0 1-.594-.24.804.804 0 0 1-.24-.594v-1.5c0-.472.122-.906.365-1.302s.566-.698.969-.906c.86-.43 1.736-.754 2.625-.97a11.48 11.48 0 0 1 2.708-.322c.917 0 1.82.108 2.708.323.89.215 1.764.538 2.625.969.403.208.726.51.969.906s.365.83.365 1.302v1.5c0 .237-.08.434-.24.594a.804.804 0 0 1-.594.24H1.667Zm13.77 0a.998.998 0 0 0 .292-.365c.07-.146.104-.309.104-.49v-1.645c0-.611-.17-1.198-.51-1.761-.34-.562-.823-1.045-1.448-1.448a10.449 10.449 0 0 1 3.75 1.167c.5.278.882.587 1.146.927.264.34.396.712.396 1.115v1.666c0 .237-.08.434-.24.594a.804.804 0 0 1-.594.24h-2.895ZM7.5 10a3.21 3.21 0 0 1-2.354-.98 3.21 3.21 0 0 1-.98-2.353c0-.917.327-1.702.98-2.354a3.21 3.21 0 0 1 2.354-.98 3.21 3.21 0 0 1 2.354.98 3.21 3.21 0 0 1 .98 2.354 3.21 3.21 0 0 1-.98 2.354A3.21 3.21 0 0 1 7.5 10Zm8.333-3.333a3.21 3.21 0 0 1-.979 2.354A3.21 3.21 0 0 1 12.5 10c-.153 0-.347-.017-.583-.052a5.151 5.151 0 0 1-.584-.115c.375-.444.663-.937.864-1.479a4.797 4.797 0 0 0 .303-1.687c0-.584-.1-1.146-.303-1.688a4.948 4.948 0 0 0-.864-1.479c.195-.07.39-.115.584-.136.194-.02.389-.03.583-.03a3.21 3.21 0 0 1 2.354.979 3.21 3.21 0 0 1 .98 2.354ZM2.5 15h10v-.667a.786.786 0 0 0-.114-.416.815.815 0 0 0-.303-.292 10.882 10.882 0 0 0-2.27-.844 9.682 9.682 0 0 0-4.625 0c-.764.188-1.521.469-2.271.844a.808.808 0 0 0-.417.708V15Zm5-6.667c.458 0 .85-.163 1.178-.49.326-.326.489-.718.489-1.176 0-.459-.163-.85-.49-1.177A1.606 1.606 0 0 0 7.5 5c-.458 0-.85.163-1.177.49-.326.326-.49.718-.49 1.177 0 .458.164.85.49 1.176.326.327.719.49 1.177.49Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgUsers;
