import { SVGProps } from "react";
const SvgTrendUp = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path fill="currentColor" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M2.7 17.3a.933.933 0 0 1-.275-.688c0-.275.091-.512.275-.712l6-6.05c.1-.083.208-.15.325-.2a.942.942 0 0 1 .375-.075c.133 0 .262.025.388.075.124.05.229.117.312.2l3.3 3.3L18.6 8H17a.967.967 0 0 1-.712-.287A.968.968 0 0 1 16 7c0-.283.096-.521.288-.713A.967.967 0 0 1 17 6h4c.283 0 .52.096.712.287.192.192.288.43.288.713v4c0 .283-.096.52-.288.712A.965.965 0 0 1 21 12a.965.965 0 0 1-.712-.288A.965.965 0 0 1 20 11V9.4l-5.9 5.9c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063 1.1 1.1 0 0 1-.375-.063.871.871 0 0 1-.325-.212L9.4 12l-5.325 5.325a.918.918 0 0 1-.675.275.96.96 0 0 1-.7-.3Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgTrendUp;
