import { factory, primaryKey } from "@mswjs/data";
import { AssessmentRound, AssessmentRoundGoals } from "../../types/AssessmentRound";
import { metaData } from "./meta";

export const mockAssessmentRoundFactory = factory({
  assessmentRound: {
    id: primaryKey(String),
    name: String,
    class: {
      id: Number,
      name: String,
      subject: String,
    },
    owner: {
      id: Number,
      display_name: String,
    },
    subject: {
      id: Number,
      name: String,
    },
    valid_from: String,
    valid_to: String,
    student_count: Number,
    assessed_count: Number,
    education_levels: Array,
    cohort: {
      id: Number,
      name: String,
      start_year: Number,
      end_year: Number,
      can_delete: Boolean,
    },
  },
});

export const assessmentRoundData: AssessmentRound[] = [
  {
    id: "01h7w2k6g2tdj2y248kk01n0y2",
    name: "Alle",
    class: {
      id: 1,
      name: "MA2_4",
      subject: "Wiskunde",
    },
    owner: {
      id: 1,
      display_name: "Test Teacher",
    },
    subject: {
      id: 1,
      name: "Wiskunde",
    },
    valid_from: "2023-08-15 00:00:00",
    valid_to: "2030-12-31 00:00:00",
    student_count: 11,
    assessed_count: 0,
    education_levels: metaData.education_levels,
    cohort: {
      id: 1,
      name: "2022 - 2025",
      start_year: 2022,
      end_year: 2025,
      can_delete: false,
    },
  },
  {
    id: "01h7w38y2vcng1jnfdrk067zxc",
    name: "Getallen",
    class: {
      id: 1,
      name: "MA2_4",
      subject: "Wiskunde",
    },
    owner: {
      id: 1,
      display_name: "Test Teacher",
    },
    subject: {
      id: 1,
      name: "Wiskunde",
    },
    valid_from: "2023-08-15 00:00:00",
    valid_to: "2030-12-31 00:00:00",
    student_count: 11,
    assessed_count: 0,
    education_levels: [
      {
        id: 2,
        label: "Geel",
        color: "#F6C86C",
        level: 2,
      },
    ],
    cohort: {
      id: 1,
      name: "2022 - 2025",
      start_year: 2022,
      end_year: 2025,
      can_delete: false,
    },
  },
  {
    id: "01h7w3asfhr8n9r0xgnd2wpbxn",
    name: "Afronden en Schatten",
    class: {
      id: 1,
      name: "MA2_4",
      subject: "Wiskunde",
    },
    owner: {
      id: 1,
      display_name: "Test Teacher",
    },
    subject: {
      id: 1,
      name: "Wiskunde",
    },
    valid_from: "2023-08-15 00:00:00",
    valid_to: "2030-12-31 00:00:00",
    student_count: 0,
    assessed_count: 0,
    education_levels: [],
    cohort: {
      id: 1,
      name: "2022 - 2025",
      start_year: 2022,
      end_year: 2025,
      can_delete: false,
    },
  },
  {
    id: "01h7w3sgf3b7tprqvxkmt3mwpe",
    name: "Afronden en Schatten #2",
    class: {
      id: 1,
      name: "MA2_4",
      subject: "Wiskunde",
    },
    owner: {
      id: 1,
      display_name: "Test Teacher",
    },
    subject: {
      id: 1,
      name: "Wiskunde",
    },
    valid_from: "2023-08-15 00:00:00",
    valid_to: "2030-12-31 00:00:00",
    student_count: 11,
    assessed_count: 0,
    education_levels: [
      {
        id: 2,
        label: "Geel",
        color: "#F6C86C",
        level: 2,
      },
    ],
    cohort: {
      id: 1,
      name: "2022 - 2025",
      start_year: 2022,
      end_year: 2025,
      can_delete: false,
    },
  },
  {
    id: "01h7yr81rc7e1cdb1mqnjfex8m",
    name: "Afronden en Schatten #3",
    class: {
      id: 1,
      name: "MA2_4",
      subject: "Wiskunde",
    },
    owner: {
      id: 1,
      display_name: "Test Teacher",
    },
    subject: {
      id: 1,
      name: "Wiskunde",
    },
    valid_from: "2023-08-16 00:00:00",
    valid_to: "2030-12-31 00:00:00",
    student_count: 11,
    assessed_count: 0,
    education_levels: [
      {
        id: 2,
        label: "Geel",
        color: "#F6C86C",
        level: 2,
      },
    ],
    cohort: {
      id: 1,
      name: "2022 - 2025",
      start_year: 2022,
      end_year: 2025,
      can_delete: false,
    },
  },
];

// create a goal for each assessmentRound
export const assessmentRoundDataGoals = (): { [assessmentRoundId: string]: AssessmentRoundGoals[] } => {
  const data: { [assessmentRoundId: string]: AssessmentRoundGoals[] } = {};

  assessmentRoundData.forEach((assessmentRound) => {
    return assessmentRound.education_levels.forEach((educationLevel) => {
      if (!data[assessmentRound.id]) {
        data[assessmentRound.id] = [];
      }
      data[assessmentRound.id].push({
        id: educationLevel.id,
        color: educationLevel.color,
        education_level_id: educationLevel.id,
        education_level_level: educationLevel.level,
        label: educationLevel.label,
        goals: [
          {
            id: 181,
            label: "Getallen",
            description: "Getallen",
            order: 1,
            sub_goals: [
              {
                id: 196,
                label: "Rekenen met getallen: +, -, x en :",
                description: "Rekenen met getallen: +, -, x en :",
                order: 1,
              },
              {
                id: 197,
                label: "Schatten",
                description: "Schatten",
                order: 2,
              },
              {
                id: 198,
                label: "Afronden",
                description: "Afronden",
                order: 3,
              },
              {
                id: 199,
                label: "Vermenigvuldigen en delen",
                description: "Vermenigvuldigen en delen",
                order: 4,
              },
              {
                id: 200,
                label: "Breuken",
                description: "Breuken",
                order: 1,
              },
            ],
          },
        ],
      });
    });
  });

  return data;
};
