import { factory, primaryKey } from "@mswjs/data";

export const mockFilterFactory = factory({
  filter: {
    id: primaryKey(Number),
    name: String,
    type: String,
    identifier: String,
    items: Array,
  },
});

export const filterData = [
  {
    id: 1,
    name: "MA1_3 - Wiskunde Q1",
    type: "REFLECTION",
    identifier: "MA1_3",
    items: [
      {
        key: "GOAL",
        value: 1,
      },
    ],
  },
];
