import { SVGProps } from "react";
const SvgAlarm = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M8.5 15.917c-.99 0-1.916-.188-2.78-.564a7.238 7.238 0 0 1-2.257-1.525 7.239 7.239 0 0 1-1.524-2.256 6.894 6.894 0 0 1-.564-2.78A6.9 6.9 0 0 1 1.94 6.01a7.25 7.25 0 0 1 1.524-2.257A7.234 7.234 0 0 1 5.72 2.231a6.886 6.886 0 0 1 2.78-.564c.99 0 1.917.188 2.781.564a7.246 7.246 0 0 1 2.256 1.523 7.257 7.257 0 0 1 1.524 2.257 6.9 6.9 0 0 1 .564 2.78c0 .99-.188 1.917-.564 2.781a7.245 7.245 0 0 1-1.524 2.256 7.25 7.25 0 0 1-2.256 1.525 6.9 6.9 0 0 1-2.78.564ZM7.708 5.625v3.147a.816.816 0 0 0 .238.574l2.236 2.236a.726.726 0 0 0 .535.218.76.76 0 0 0 .554-.238.75.75 0 0 0 .218-.554.75.75 0 0 0-.218-.554L9.29 8.475v-2.87a.734.734 0 0 0-.226-.554.786.786 0 0 0-.565-.218.765.765 0 0 0-.564.228.767.767 0 0 0-.228.564ZM1.712 4.299a.727.727 0 0 1-.535.218.76.76 0 0 1-.554-.238.75.75 0 0 1-.218-.554.75.75 0 0 1 .218-.554L2.899.895a.727.727 0 0 1 .534-.218.76.76 0 0 1 .555.238.75.75 0 0 1 .217.554.75.75 0 0 1-.217.554L1.712 4.299Zm13.557-.02-2.276-2.276a.726.726 0 0 1-.218-.534.76.76 0 0 1 .238-.554.75.75 0 0 1 .554-.218.75.75 0 0 1 .554.218l2.276 2.276a.726.726 0 0 1 .218.534.76.76 0 0 1-.238.554.75.75 0 0 1-.554.218.75.75 0 0 1-.554-.218ZM8.5 14.333c1.544 0 2.853-.537 3.928-1.613 1.076-1.075 1.614-2.385 1.614-3.928 0-1.544-.538-2.854-1.614-3.93C11.353 3.789 10.044 3.25 8.5 3.25s-2.853.538-3.928 1.613C3.496 5.938 2.958 7.248 2.958 8.792c0 1.543.538 2.853 1.614 3.928 1.075 1.076 2.384 1.613 3.928 1.613Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAlarm;
