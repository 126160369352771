import { SVGProps } from "react";
const SvgAcute = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={25}
    >
      <path fill="currentColor" d="M0 .055h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M15 20.055c-2.233 0-4.125-.775-5.675-2.325C7.775 16.18 7 14.29 7 12.055c0-2.216.775-4.104 2.325-5.663 1.55-1.558 3.442-2.337 5.675-2.337 2.217 0 4.104.78 5.663 2.337C22.221 7.951 23 9.84 23 12.055c0 2.234-.779 4.125-2.337 5.675-1.559 1.55-3.446 2.325-5.663 2.325Zm0-2c1.667 0 3.083-.583 4.25-1.75 1.167-1.166 1.75-2.583 1.75-4.25 0-1.666-.583-3.083-1.75-4.25-1.167-1.166-2.583-1.75-4.25-1.75-1.667 0-3.083.584-4.25 1.75C9.583 8.972 9 10.39 9 12.055c0 1.667.583 3.084 1.75 4.25 1.167 1.167 2.583 1.75 4.25 1.75Zm1-6.4v-2.6a.97.97 0 0 0-.287-.713.97.97 0 0 0-.713-.287.967.967 0 0 0-.712.287.968.968 0 0 0-.288.713v3.025c0 .134.03.263.088.387.058.126.129.23.212.313l2.275 2.275c.2.2.438.3.713.3.275 0 .512-.1.712-.3.2-.2.3-.437.3-.713 0-.274-.1-.512-.3-.712l-2-1.975Zm-13-2.6a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2 8.055c0-.283.096-.52.288-.713A.967.967 0 0 1 3 7.055h2a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .284-.096.521-.287.712A.968.968 0 0 1 5 9.055H3Zm-1 4a.965.965 0 0 1-.712-.288.965.965 0 0 1-.288-.712c0-.283.096-.52.288-.713A.967.967 0 0 1 2 11.055h3a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .284-.096.521-.287.712a.968.968 0 0 1-.713.288H2Zm1 4a.965.965 0 0 1-.712-.288.965.965 0 0 1-.288-.712c0-.283.096-.52.288-.713A.967.967 0 0 1 3 15.055h2a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .284-.096.521-.287.712a.968.968 0 0 1-.713.288H3Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgAcute;
