import { createContext, FC, PropsWithChildren, useContext } from "react";
import { AuthorizationPermissionKey, AuthorizationRoleKey } from "../types/Authorization";
import { useMeta } from "./MetaContext";

export type HasPermissionOptions = {
  strategy?: ValidatePermissionStrategy;
};

export type AuthorizationContextType = {
  hasPermission: (
    permissions: AuthorizationPermissionKey | AuthorizationPermissionKey[],
    options?: HasPermissionOptions
  ) => boolean;
  hasRole: (permissions: AuthorizationRoleKey | AuthorizationRoleKey[], options?: HasPermissionOptions) => boolean;
};

export type ValidatePermissionStrategy = "all" | "some";

export function useAuthorization() {
  const context = useContext(AuthorizationContext);

  if (!context) {
    throw new Error("useAuthorization must be used within a AuthorizationContextProvider");
  }

  return context;
}

const defaultState = {
  hasPermission: () => {
    return false;
  },
  hasRole: () => {
    return false;
  },
};
export const AuthorizationContext = createContext<AuthorizationContextType>(defaultState);
export const AuthorizationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { authorisation } = useMeta();

  const hasPermission = (
    permissions: AuthorizationPermissionKey | AuthorizationPermissionKey[],
    { strategy = "all" }: HasPermissionOptions = {}
  ) => {
    const permissionKeys = authorisation.permissions.flatMap((permission) => permission.key);

    if (Array.isArray(permissions)) {
      if (strategy === "some") {
        return permissions.some((permission) => permissionKeys.includes(permission));
      }

      return permissions.every((permission) => permissionKeys.includes(permission));
    }

    return permissionKeys.includes(permissions);
  };

  const hasRole = (
    roles: AuthorizationRoleKey | AuthorizationRoleKey[],
    { strategy = "all" }: HasPermissionOptions = {}
  ) => {
    const roleKeys = authorisation.roles.flatMap((role) => role.key);

    if (Array.isArray(roles)) {
      if (strategy === "some") {
        return roles.some((role) => roleKeys.includes(role));
      }

      return roles.every((role) => roleKeys.includes(role));
    }

    return authorisation.roles.flatMap((role) => role.key).includes(roles);
  };

  const value = {
    ...defaultState,
    hasPermission,
    hasRole,
  };

  return <AuthorizationContext.Provider value={value}>{children}</AuthorizationContext.Provider>;
};
