import { SVGProps } from "react";
const SvgOpenMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path fill="currentColor" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M4 18a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 17c0-.283.096-.52.288-.712A.967.967 0 0 1 4 16h16c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 20 18H4Zm0-5a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 12c0-.283.096-.52.288-.713A.967.967 0 0 1 4 11h16c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 20 13H4Zm0-5a.968.968 0 0 1-.712-.287A.968.968 0 0 1 3 7c0-.283.096-.52.288-.713A.968.968 0 0 1 4 6h16c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 20 8H4Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgOpenMenu;
