import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_ENDPOINT } from "../../constants";
import { ApiBaseResponse } from "../../types/ApiBaseResponse";
import { requestJson } from "../api";
import { metaKeys } from "../metas/metaKeys";
import { usersKeys } from "./usersKeys";

export const usePatchUnlock = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    usersKeys.lock,
    () =>
      requestJson<ApiBaseResponse>(`${API_ENDPOINT}/app/unlock`, {
        method: "PATCH",
      }).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(metaKeys.all);
      },
    }
  );

  return mutation;
};
