import { factory, primaryKey } from "@mswjs/data";

export const mockCohortFactory = factory({
  cohort: {
    id: primaryKey(Number),
    name: String,
    start_year: Number,
    end_year: Number,
  },
});

export const cohortData = [
  {
    id: 1,
    name: "2022-2023",
    start_year: 2022,
    end_year: 2023,
    can_delete: false,
    periods: [],
  },
  {
    id: 2,
    name: "2023-2024",
    start_year: 2023,
    end_year: 2024,
    can_delete: false,
    periods: [],
  },
  {
    id: 3,
    name: "2024-2025",
    start_year: 2024,
    end_year: 2025,
    can_delete: false,
    periods: [],
  },
  {
    id: 4,
    name: "2025-2026",
    start_year: 2025,
    end_year: 2025,
    can_delete: false,
    periods: [],
  },
];
