import { factory, primaryKey } from "@mswjs/data";

export const mockGoalFactory = factory({
  goal: {
    id: primaryKey(Number),
    label: String,
    description: String,
    order: Number,
    sub_goals: Array,
  },
});

export const goalData = [
  {
    id: 1,
    label: "Informatieverwerking en statistiek",
    description: "Informatieverwerking en statistiek",
    order: 1,
    sub_goals: [
      {
        id: 16,
        label: "Centrummaten",
        description: "",
        order: 1,
      },
      {
        id: 17,
        label: "Diagrammen",
        description: "",
        order: 2,
      },
      {
        id: 18,
        label: "Telproblemen",
        description: "",
        order: 3,
      },
      {
        id: 19,
        label: "Kans",
        description: "",
        order: 4,
      },
    ],
  },
  {
    id: 2,
    label: "Vlakke meetkunde",
    description: "Vlakke meetkunde",
    order: 2,
    sub_goals: [
      {
        id: 21,
        label: "Hoeken in vlakke figuren",
        description: "",
        order: 1,
      },
      {
        id: 22,
        label: "Eigenschappen vlakke figuren",
        description: "",
        order: 2,
      },
      {
        id: 23,
        label: "Lijnsymmetrie",
        description: "",
        order: 3,
      },
      {
        id: 24,
        label: "Oppervlakte en omtrek",
        description: "",
        order: 4,
      },
    ],
  },
  {
    id: 3,
    label: "Ruimte meetkunde",
    description: "Ruimte meetkunde",
    order: 3,
    sub_goals: [
      {
        id: 26,
        label: "Eigenschappen ruimtefiguren",
        description: "",
        order: 1,
      },
      {
        id: 27,
        label: "Inhoud en oppervlakte",
        description: "",
        order: 2,
      },
      {
        id: 28,
        label: "Aanzichten",
        description: "",
        order: 3,
      },
      {
        id: 29,
        label: "Doorsneden",
        description: "",
        order: 4,
      },
      {
        id: 30,
        label: "Kijklijnen en kijkhoek",
        description: "",
        order: 5,
      },
    ],
  },
];
