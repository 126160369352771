import React from "react";
import { ButtonProps, ButtonSize, ButtonVariant } from "./Button.types";
import styles from "./Button.module.scss";
import classNames from "classnames";
import { Spinner } from "../Spinner/Spinner";

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      size = ButtonSize.MEDIUM,
      variant = ButtonVariant.PRIMARY,
      icon = null,
      loading = false,
      disabled,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        className={classNames(styles.Button, { [styles.ButtonLoading]: loading }, className)}
        data-size={size}
        data-variant={variant}
        disabled={loading || disabled}
        {...props}
      >
        <div className={styles.ButtonContent}>
          {icon}
          {React.Children.count(children) > 0 && <span>{children}</span>}
        </div>
        {loading && (
          <div className={styles.ButtonContentLoading}>
            <Spinner />
          </div>
        )}
      </button>
    );
  }
);

Button.displayName = "Button";
