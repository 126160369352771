import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { AppProps } from "next/app";
import Head from "next/head";
import { I18nextProvider } from "react-i18next";
import { AwsAmplifyAuthenticationProvider } from "../auth/AwsAmplifyAuthenticationProvider";
import { AppLoader } from "../components/atoms/AppLoader/AppLoader";
import { ColorMapper } from "../components/atoms/ColorMapper/ColorMapper";
import { ToastArea } from "../components/molecules/Toast/ToastArea";
import { AuthorizationContextProvider } from "../context/AuthorizationContext";
import { MetaContextProvider } from "../context/MetaContext";
import { UserLockContextProvider } from "../context/UserLockContext";
import i18n from "../i18n";
import { worker } from "../mocks/browser";
import { ErrorBoundary } from "../services/bugsnag";
import { queryClient } from "../services/client";
import "../styles/globals.css";
import ErrorPage from "./500";

// Enable API mocking in development
if (global.window !== undefined && process.env.NEXT_PUBLIC_API_MOCKING === "true") {
  worker().start();
}

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex,nofollow" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Head>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <I18nextProvider i18n={i18n} defaultNS="common">
          <QueryClientProvider client={queryClient}>
            <AwsAmplifyAuthenticationProvider>
              <ToastArea>
                <MetaContextProvider>
                  <AppLoader>
                    <UserLockContextProvider>
                      <AuthorizationContextProvider>
                        <Component {...pageProps} />
                        <ColorMapper />
                        <ReactQueryDevtools
                          initialIsOpen={true}
                          toggleButtonProps={{ style: { right: 0, left: undefined } }}
                        />
                      </AuthorizationContextProvider>
                    </UserLockContextProvider>
                  </AppLoader>
                </MetaContextProvider>
              </ToastArea>
            </AwsAmplifyAuthenticationProvider>
          </QueryClientProvider>
        </I18nextProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
