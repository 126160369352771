import { SVGProps } from "react";

const SvgNewTab = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.97222 17.75C1.42986 17.75 0.965567 17.5569 0.57934 17.1707C0.193113 16.7844 0 16.3201 0 15.7778V1.97222C0 1.42986 0.193113 0.965567 0.57934 0.57934C0.965567 0.193113 1.42986 0 1.97222 0H7.88889C8.16829 0 8.40249 0.0945023 8.59149 0.283507C8.7805 0.472512 8.875 0.706713 8.875 0.986111C8.875 1.26551 8.7805 1.49971 8.59149 1.68872C8.40249 1.87772 8.16829 1.97222 7.88889 1.97222H1.97222V15.7778H15.7778V9.86111C15.7778 9.58171 15.8723 9.34751 16.0613 9.15851C16.2503 8.9695 16.4845 8.875 16.7639 8.875C17.0433 8.875 17.2775 8.9695 17.4665 9.15851C17.6555 9.34751 17.75 9.58171 17.75 9.86111V15.7778C17.75 16.3201 17.5569 16.7844 17.1707 17.1707C16.7844 17.5569 16.3201 17.75 15.7778 17.75H1.97222ZM15.7778 3.35278L7.29722 11.8333C7.11644 12.0141 6.88634 12.1045 6.60694 12.1045C6.32755 12.1045 6.09745 12.0141 5.91667 11.8333C5.73588 11.6525 5.64549 11.4225 5.64549 11.1431C5.64549 10.8637 5.73588 10.6336 5.91667 10.4528L14.3972 1.97222H11.8333C11.5539 1.97222 11.3197 1.87772 11.1307 1.68872C10.9417 1.49971 10.8472 1.26551 10.8472 0.986111C10.8472 0.706713 10.9417 0.472512 11.1307 0.283507C11.3197 0.0945023 11.5539 0 11.8333 0H17.75V5.91667C17.75 6.19606 17.6555 6.43027 17.4665 6.61927C17.2775 6.80828 17.0433 6.90278 16.7639 6.90278C16.4845 6.90278 16.2503 6.80828 16.0613 6.61927C15.8723 6.43027 15.7778 6.19606 15.7778 5.91667V3.35278Z"
      fill="#021709"
    />
  </svg>
);
export default SvgNewTab;
