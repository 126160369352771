import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { mockClassesFactory } from "../mockdata/classes";

export const classesHandlers = [
  rest.get(`${API_ENDPOINT}/app/classes`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockClassesFactory.classes.getAll(),
      })
    );
  }),
];
