import { SVGProps } from "react";
const SvgMessages = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M17.281 7.438H10.72a3.275 3.275 0 0 0-3.281 3.268v4.58a3.276 3.276 0 0 0 3.28 3.268h.985c.177 0 .414.119.525.263l.985 1.306c.433.577 1.141.577 1.575 0l.984-1.306a.665.665 0 0 1 .525-.263h.984a3.275 3.275 0 0 0 3.282-3.268v-4.58a3.275 3.275 0 0 0-3.282-3.268Zm-2.625 7.71H10.72a.496.496 0 0 1-.492-.492c0-.269.223-.492.492-.492h3.937c.27 0 .492.223.492.492 0 .27-.223.492-.492.492Zm2.625-3.28H10.72a.496.496 0 0 1-.492-.493c0-.27.223-.492.492-.492h6.562c.27 0 .492.223.492.492 0 .27-.223.492-.492.492Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMessages;
