import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { mockClassFactory } from "../mockdata/class";
import { mockSubjectFactory } from "../mockdata/subject";

export const subjectHandlers = [
  rest.get(`${API_ENDPOINT}/app/subjects`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockSubjectFactory.subject.getAll(),
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/subjects/:subject_id/classes`, (req, res, ctx) => {
    const { subject_id } = req.params;
    const subject = mockSubjectFactory.subject.findFirst({
      where: {
        id: {
          equals: Number(subject_id),
        },
      },
    });

    if (!subject) {
      return res(
        ctx.status(404),
        ctx.json({
          status: 404,
          message: "Subject not found",
        })
      );
    }

    return res(
      ctx.status(200),
      ctx.json({
        data: mockClassFactory.class.findMany({
          where: {
            subject: {
              contains: subject.name,
            },
          },
        }),
      })
    );
  }),
];
