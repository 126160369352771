import { SVGProps } from "react";
const SvgTrendFlat = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path fill="currentColor" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M18.175 13H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 12c0-.283.096-.521.288-.713A.967.967 0 0 1 4 11h14.175l-1.4-1.4a.891.891 0 0 1-.262-.688c.008-.275.112-.512.312-.712a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l3.075 3.1c.1.1.175.208.225.325.05.117.067.242.05.375 0 .133-.02.258-.062.375a.883.883 0 0 1-.213.325l-3.1 3.1c-.2.2-.437.296-.712.287a.976.976 0 0 1-.688-.287c-.2-.2-.3-.438-.3-.713 0-.275.092-.504.275-.687l1.4-1.4Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgTrendFlat;
