import { SVGProps } from "react";
const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M8 8.25c.213 0 .39-.072.535-.216A.725.725 0 0 0 8.75 7.5V4.481a.696.696 0 0 0-.215-.525A.744.744 0 0 0 8 3.75a.725.725 0 0 0-.534.215.726.726 0 0 0-.216.535v3.019c0 .212.072.387.216.525A.742.742 0 0 0 8 8.25Zm0 3c.213 0 .39-.072.535-.216a.725.725 0 0 0 .215-.534.728.728 0 0 0-.215-.535A.728.728 0 0 0 8 9.75a.725.725 0 0 0-.534.215.726.726 0 0 0-.216.535c0 .213.072.39.216.534A.724.724 0 0 0 8 11.25ZM8 15a7.3 7.3 0 0 1-2.925-.591 7.569 7.569 0 0 1-2.381-1.603 7.569 7.569 0 0 1-1.603-2.381A7.3 7.3 0 0 1 .5 7.5a7.3 7.3 0 0 1 .591-2.925 7.569 7.569 0 0 1 1.603-2.381A7.58 7.58 0 0 1 5.075.59 7.307 7.307 0 0 1 8 0c1.037 0 2.012.197 2.925.59a7.58 7.58 0 0 1 2.381 1.604 7.569 7.569 0 0 1 1.603 2.381A7.3 7.3 0 0 1 15.5 7.5a7.3 7.3 0 0 1-.591 2.925 7.569 7.569 0 0 1-1.603 2.381 7.569 7.569 0 0 1-2.381 1.603A7.3 7.3 0 0 1 8 15Zm0-1.5c1.662 0 3.078-.584 4.247-1.753S14 9.162 14 7.5s-.584-3.078-1.753-4.247S9.662 1.5 8 1.5s-3.078.584-4.247 1.753S2 5.838 2 7.5s.584 3.078 1.753 4.247S6.338 13.5 8 13.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgError;
