import { FC, PropsWithChildren, ReactElement, useContext, useEffect, useState } from "react";
import { Spinner } from "../Spinner/Spinner";
import styles from "./AppLoader.module.scss";
import { useAuthenticationStatus } from "../../../auth/AuthenticationContext";
import Icon from "../icons";
import { useTranslation } from "react-i18next";
import { MetaContext } from "../../../context/MetaContext";
import { Button, ButtonVariant } from "../Button";
import { ButtonSize } from "../Button/Button.types";

type AppLoaderStatus = "ready" | "loading" | "error";

export const AppLoader: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);

  const { status: metaStatus } = useContext(MetaContext);
  const authStatus = useAuthenticationStatus();

  // Check if authentication check is done or meta query is loading
  const status =
    authStatus === "initializing" || metaStatus === "loading" ? "loading" : metaStatus === "error" ? "error" : "ready";

  const handleClearCache = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (status === "ready") {
      setLoaded(true);
    }
  }, [status]);

  if (!loaded && status !== "ready") {
    return (
      <div className={styles.AppLoader} data-testid="app-loader">
        {status === "loading" && (
          <div className={styles.AppLoaderSpinner} data-testid="app-loader-loading">
            <Spinner />
            <span>{t("app_loader.text")}</span>
          </div>
        )}
        {/* @ts-ignore */}
        {status === "error" && (
          <div className={styles.AppLoaderError} data-testid="app-loader-error">
            <Icon name="warning" />
            <span>{t("app_loader.error")}</span>
            <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.SMALL} onClick={handleClearCache}>
              {t("app_loader.retry")}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return children as ReactElement;
};
