import classNames from "classnames";
import { Close } from "../../atoms/icons";
import styles from "./Toast.module.scss";
import { useTranslation } from "react-i18next";

export type ToastType = "error" | "success" | "warning" | "info";
export type ToastProps = {
  className?: string;
  type: ToastType;
  title?: string;
  message?: string;
  id?: string;
  onDismiss?: () => void;
};

export const Toast: React.FC<ToastProps> = ({ className, title, type, message, onDismiss, id }) => {
  const { t } = useTranslation();
  const _title = title ?? t(`toast.title.${type}`);
  // if no translation item found, fallback to message
  const _message =
    t(`toast.message.${message}`) === `toast.message.${message}` ? message : t(`toast.message.${message}`);

  return (
    <div
      className={classNames(styles.toast, className)}
      data-testid="toast"
      data-type={type}
      tabIndex={-1}
      data-state={type}
      id={id}
    >
      <div className={styles.toast__body}>
        <div className={styles.toast__header}>
          <span className={styles.toast__title}>{_title}</span>
        </div>
        <div data-testid="toast__content" className={styles.toast__content}>
          {_message as React.ReactNode}
        </div>
      </div>
      <button className={styles.toast__closeButton} onClick={onDismiss} tabIndex={-1}>
        <Close />
      </button>
    </div>
  );
};
