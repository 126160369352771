import { SVGProps } from "react";
const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M21 11.564V8.386C21 7.399 20.552 7 19.439 7H16.61c-1.113 0-1.561.399-1.561 1.386v3.171c0 .994.448 1.386 1.56 1.386h2.829c1.113.007 1.56-.392 1.56-1.379ZM21 19.439v-2.828c0-1.113-.448-1.561-1.561-1.561H16.61c-1.113 0-1.561.448-1.561 1.561v2.828c0 1.113.448 1.561 1.56 1.561h2.829c1.113 0 1.56-.448 1.56-1.561ZM12.95 11.564V8.386c0-.987-.448-1.386-1.561-1.386H8.561C7.448 7 7 7.399 7 8.386v3.171c0 .994.448 1.386 1.561 1.386h2.828c1.113.007 1.561-.392 1.561-1.379ZM12.95 19.439v-2.828c0-1.113-.448-1.561-1.561-1.561H8.561C7.448 15.05 7 15.498 7 16.611v2.828C7 20.552 7.448 21 8.561 21h2.828c1.113 0 1.561-.448 1.561-1.561Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgDashboard;
