import { factory, primaryKey } from "@mswjs/data";

export const mockDashboardFactory = factory({
  classes: {
    id: primaryKey(Number),
    name: String,
    subject: String,
    students_amount: Number,
    mentor: String,
  },
});

export const dashboardData = [
  {
    id: 1,
    name: "1A",
    subject: "Wiskunde",
    students_amount: 20,
    mentor: "J. van der Linden",
  },
];
