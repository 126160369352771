import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import * as translationsEng from "./locales/en";
import * as translationsNl from "./locales/nl";

declare module "react-i18next" {
  interface CustomTypeOptions {
    defaultNS: "common";
    resources: typeof translationsEng;
  }
}
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const i18nNamespaces = ["common", "errors"] as const;

const i18nConfig = {
  resources: {
    en: translationsEng,
    nl: translationsNl,
  },
  lng: "nl",
  fallbackLng: "nl",
  defaultNS: "common",
  ns: i18nNamespaces,
  interpolation: { escapeValue: false },
  react: { useSuspense: false },
  supportedLngs: ["nl", "en"],
  returnNull: false,
  debug: process.env.NODE_ENV === "development",
} as InitOptions;

i18n.use(initReactI18next).init(i18nConfig);

export default i18n;
