import { SVGProps } from "react";
const SvgRubrics = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M15.75 20.27a.435.435 0 0 1-.373-.66l.612-1.02a.437.437 0 0 1 .6-.15c.21.122.275.39.152.6l-.157.262a3.658 3.658 0 0 0 2.817-3.552.44.44 0 0 1 .438-.438.44.44 0 0 1 .438.438 4.537 4.537 0 0 1-4.527 4.52ZM8.166 12.688a.44.44 0 0 1-.437-.438c0-2.49 2.03-4.52 4.52-4.52a.435.435 0 0 1 .373.66l-.611 1.025a.435.435 0 0 1-.601.146.435.435 0 0 1-.146-.601l.158-.262a3.652 3.652 0 0 0-2.818 3.552.44.44 0 0 1-.438.438ZM13.224 15.08l-1.832-.987a.646.646 0 0 0-.618 0l-1.832.986a.417.417 0 0 0-.215.373c0 .158.081.304.215.374l1.832.986c.1.052.204.075.31.075.104 0 .21-.023.308-.075l1.832-.986a.418.418 0 0 0 .216-.373.436.436 0 0 0-.216-.374ZM10.47 17.156l-1.703-.852a.424.424 0 0 0-.408.018.427.427 0 0 0-.193.355v1.61c0 .28.152.531.403.654l1.703.852a.51.51 0 0 0 .187.04.418.418 0 0 0 .42-.42v-1.61a.721.721 0 0 0-.408-.647ZM13.802 16.322a.423.423 0 0 0-.409-.018l-1.703.852a.718.718 0 0 0-.402.653v1.61c0 .146.075.28.198.356a.42.42 0 0 0 .408.012l1.704-.852a.718.718 0 0 0 .402-.653v-1.61a.416.416 0 0 0-.198-.35ZM19.058 9.234l-1.832-.986a.646.646 0 0 0-.618 0l-1.832.986a.417.417 0 0 0-.216.373c0 .158.082.304.216.374l1.832.986c.099.052.204.075.309.075.105 0 .21-.023.309-.075l1.832-.986a.418.418 0 0 0 .215-.374.422.422 0 0 0-.215-.373ZM16.304 11.305l-1.703-.852a.424.424 0 0 0-.409.018.427.427 0 0 0-.192.356v1.61c0 .28.152.53.402.653l1.704.852a.42.42 0 0 0 .186.046.418.418 0 0 0 .42-.42v-1.61c0-.28-.157-.53-.408-.653ZM19.635 10.47a.423.423 0 0 0-.409-.017l-1.703.852a.718.718 0 0 0-.402.653v1.61a.418.418 0 0 0 .606.374l1.704-.852a.731.731 0 0 0 .402-.653v-1.61a.418.418 0 0 0-.198-.356Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRubrics;
