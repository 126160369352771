import React, { useContext, useEffect } from "react";
import { usePatchLock } from "../services/users/usePatchLock";
import { usePatchUnlock } from "../services/users/usePatchUnlock";
import { useMeta } from "./MetaContext";

type UserLockContextType = {
  isLocked: boolean | null;
  setLocked: (locked: boolean) => void;
};

type Props = {
  children: React.ReactNode;
};

export const UserLockContext = React.createContext<UserLockContextType>({
  isLocked: null,
  setLocked: (locked: boolean) => {},
});

export const useUserLock = () => {
  const context = useContext(UserLockContext);

  if (!context) {
    throw new Error("useUserLock must be used within a UserLockContext");
  }

  return context;
};

export const UserLockContextProvider: React.FC<Props> = ({ children }) => {
  const data = useMeta();
  const { mutate: mutateLock } = usePatchLock();
  const { mutate: mutateUnlock } = usePatchUnlock();
  const [isLocked, setIsLocked] = React.useState<boolean | null>(null);
  const setLocked = (locked: boolean) => {
    if (locked) {
      mutateLock();
    } else {
      mutateUnlock();
    }
    setIsLocked(locked);
  };

  // update locked state when user changes
  useEffect(() => {
    if (data?.user.is_readonly !== undefined) {
      setIsLocked(Boolean(data?.user.is_readonly));
    }
  }, [data?.user.is_readonly, data.student]);

  return <UserLockContext.Provider value={{ isLocked, setLocked }}>{children}</UserLockContext.Provider>;
};
