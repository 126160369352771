import { GetUserOptions } from "./useGetUsers";

export const usersKeys = {
  all: (queryParams: GetUserOptions["queryParams"]) => [
    "users",
    "all",
    queryParams?.page,
    queryParams?.["filter[search]"],
    queryParams?.["filter[is_active]"],
  ],
  one: (id: string) => ["users", id] as const,
  lock: ["users", "lock"] as const,
  unlock: ["users", "unlock"] as const,
  impersonation: ["users", "impersonate"] as const,
};
