import { createContext, useContext } from "react";

export type AuthenticationStatus = "initializing" | "authenticated" | "unauthenticated" | "error";

export type AuthenticationContextType = {
  status: AuthenticationStatus;

  signIn: () => void;
  signOut: () => void;
};

export const AuthenticationContext = createContext<AuthenticationContextType>({
  status: "initializing",
  signIn: () => {},
  signOut: () => {},
});

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error("useAuthentication must be used within an AuthenticationContext");
  }

  return context;
};

export const useAuthenticationStatus = () => {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error("useAuthenticationStatus must be used within an AuthenticationContext");
  }

  return context.status;
};
