import { SVGProps } from "react";
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path fill="currentColor" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M12 14.95a1.1 1.1 0 0 1-.375-.063.871.871 0 0 1-.325-.212L6.675 10.05a.891.891 0 0 1-.262-.688.975.975 0 0 1 .287-.687.948.948 0 0 1 .7-.275c.284 0 .517.092.7.275l3.9 3.9 3.925-3.925a.894.894 0 0 1 .688-.263.978.978 0 0 1 .687.288.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-4.6 4.6c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgChevronDown;
