import Head from "next/head";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { createPageTitle } from "../../../helpers/createPageTitle";
import { Button } from "../../atoms/Button";
import React from "react";
import styles from "../../layouts/AuthenticatedLayout/AuthenticatedLayout.module.scss";

type Props = {
  messages: {
    meta: string;
    code: string;
    title: string;
    back: string;
  };
  children?: React.ReactNode;
};
export const ErrorPage: React.FC<Props> = ({ messages }) => {
  // 404.tsx and 500.tsx pass errors
  const { t } = useTranslation(["common", "errors"]);

  return (
    <>
      <Head>
        <title>{createPageTitle(t, t(messages.meta))}</title>
      </Head>
      <div className={styles.errorPage}>
        <img src="/marcanti-logo.png" className={styles.errorPageLogo} alt="Marcanti College" />
        <p className={styles.errorFooter}>{t("auth_footer_text")}</p>
        <div className={styles.contentSide}>
          <div className={styles.error}>
            <p className={styles.errorPageCode}>{t(messages.code)}</p>
            <h1 className={styles.errorPageTitle}>{t(messages.title)}</h1>
            <Link href="/">
              <Button>{t(messages.back)}</Button>
            </Link>
          </div>
        </div>
        <div className={styles.imageSide}>
          <div className={styles.errorPageIllustration}></div>
        </div>
      </div>
    </>
  );
};
