import { SVGProps } from "react";
const SvgRoles = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="b"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="currentColor" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#b)">
      <path
        d="M14.167 14.167c.347 0 .642-.122.885-.365s.365-.538.365-.885c0-.348-.122-.643-.365-.886a1.206 1.206 0 0 0-.885-.364c-.348 0-.643.121-.886.364a1.205 1.205 0 0 0-.364.886c0 .347.121.642.364.885s.538.365.886.365Zm0 2.5c.43 0 .826-.101 1.187-.302.361-.202.653-.47.875-.802A4 4 0 0 0 14.167 15a4 4 0 0 0-2.063.563c.222.333.514.6.875.802.361.201.757.302 1.188.302Zm0 1.666c-1.153 0-2.136-.406-2.948-1.218-.813-.813-1.219-1.796-1.219-2.948 0-1.153.406-2.136 1.219-2.948.812-.813 1.795-1.219 2.948-1.219 1.152 0 2.135.406 2.948 1.219.812.812 1.218 1.795 1.218 2.948 0 1.152-.406 2.135-1.218 2.948-.813.812-1.795 1.218-2.948 1.218Zm-4.365-.062a.535.535 0 0 1-.198-.042c-1.82-.555-3.32-1.68-4.5-3.375-1.18-1.694-1.77-3.562-1.77-5.604V5.312c0-.347.1-.66.301-.937a1.72 1.72 0 0 1 .782-.604l5-1.875c.194-.07.389-.104.583-.104.194 0 .389.034.583.104l5 1.875c.32.125.58.326.782.604.201.278.302.59.302.937v3.584a10.283 10.283 0 0 0-.813-.302A5.085 5.085 0 0 0 15 8.396V5.333l-5-1.875-5 1.875V9.25c0 .653.087 1.306.26 1.958.174.653.417 1.275.73 1.865.312.59.69 1.135 1.135 1.635.444.5.938.917 1.48 1.25.152.445.346.861.583 1.25.236.39.513.743.833 1.063h-.219Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgRoles;
