import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { mockClassFactory } from "../mockdata/class";

export const classHandlers = [
  rest.get(`${API_ENDPOINT}/app/classes/:class_id`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockClassFactory.class.getAll()[0],
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/classes/:class_id/students`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockClassFactory.students.getAll(),
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/classes/:class_id/statistics`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockClassFactory.statistics.getAll(),
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/classes/:class_id/goals`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockClassFactory.goals.getAll(),
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/classes/:class_id/periods`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockClassFactory.periods.getAll(),
      })
    );
  }),
];
