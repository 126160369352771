import { createContext, FC, PropsWithChildren, useContext } from "react";
import { useGetMeta } from "../services/metas/useGetMeta";
import { Meta } from "../types/Meta";
import { EducationLevel } from "../types/EducationLevel";
import { useAuthenticationStatus } from "../auth/AuthenticationContext";

export type MetaContextType = {
  meta: Meta;
  status: "disabled" | "loading" | "success" | "error";
};

export function useMeta() {
  const context = useContext(MetaContext);

  if (!context) {
    throw new Error("useMeta must be used within a MetaContextProvider");
  }

  return context.meta;
}

export const getEducationLevelById = (educationLevels: EducationLevel[], educationLevelId: number | undefined) => {
  return educationLevels.find((educationLevel) => educationLevel.id === educationLevelId);
};

const defaultMetaState = {
  skill_levels: [],
  education_levels: [],
  authorisation: {
    permissions: [],
    roles: [],
  },
  statistics: {
    high_skill_percentage: 80,
    low_skill_percentage: 20,
  },
  user: {
    id: 0,
    display_name: "",
    family_name: "",
    given_name: "",
    is_readonly: true,
    last_active: "",
    name_insertion: "",
    school_number: "",
    email: "",
    is_impersonated: false,
  },
  student: {
    id: 0,
  },
  getEducationLevelById,
};

export const MetaContext = createContext<MetaContextType>({
  meta: defaultMetaState,
  status: "disabled",
});

export const MetaContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const authStatus = useAuthenticationStatus();
  const isEnabled = authStatus === "authenticated";

  const { data, isFetching } = useGetMeta({ enabled: isEnabled });

  const status = !isEnabled ? "disabled" : isFetching && !data ? "loading" : data ? "success" : "error";

  return (
    <MetaContext.Provider value={{ meta: { ...defaultMetaState, ...data }, status }}>{children}</MetaContext.Provider>
  );
};
