import { assessmentRoundsHandlers } from "./assessmentRounds";
import { classHandlers } from "./class";
import { classesHandlers } from "./classes";
import { cohortsHandlers } from "./cohort";
import { dashboardHandlers } from "./dashboard";
import { filterHandlers } from "./filters";
import { goalHandlers } from "./goal";
import { metaHandlers } from "./meta";
import { periodsHandlers } from "./period";
import { reportHandlers } from "./report";
import { skillHandlers } from "./skill";
import { subjectHandlers } from "./subject";

export const mockHandlers = [
  ...metaHandlers,
  ...subjectHandlers,
  ...goalHandlers,
  ...classHandlers,
  ...skillHandlers,
  ...subjectHandlers,
  ...periodsHandlers,
  ...cohortsHandlers,
  ...dashboardHandlers,
  ...assessmentRoundsHandlers,
  ...filterHandlers,
  ...classesHandlers,
  ...reportHandlers,
];
