import { SVGProps } from "react";
const SvgVerticalDrag = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M13.26 14.711H8.5a.5.5 0 0 1-.5-.5v-.451a.5.5 0 0 1 .5-.5h4.76V8.478l-.936.944a.731.731 0 1 1-1.038-1.03l2.177-2.177a.726.726 0 0 1 1.03 0l2.177 2.177a.725.725 0 0 1-1.023 1.023l-.936-.937v4.782h4.79a.5.5 0 0 1 .5.5l-.001.452a.5.5 0 0 1-.5.5h-4.789v4.782l.95-.944a.726.726 0 0 1 1.024 1.023l-2.177 2.177a.726.726 0 0 1-1.03 0L11.3 19.573a.726.726 0 0 1 1.023-1.023l.936.943v-4.781Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgVerticalDrag;
