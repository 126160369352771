import { SVGProps } from "react";
const SvgPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M12.031 7.438a3.12 3.12 0 0 0-3.117 3.117c0 1.686 1.32 3.051 3.039 3.11a.535.535 0 0 1 .144 0h.046a3.11 3.11 0 0 0 3.005-3.11 3.12 3.12 0 0 0-3.117-3.117ZM15.365 15.411c-1.831-1.22-4.817-1.22-6.661 0-.833.558-1.293 1.313-1.293 2.12 0 .807.46 1.555 1.286 2.106.92.617 2.127.925 3.334.925 1.208 0 2.415-.308 3.334-.925.827-.558 1.286-1.306 1.286-2.12-.006-.807-.46-1.555-1.286-2.106ZM19.244 10.942a2.349 2.349 0 0 1-2.055 2.54h-.033c-.039 0-.078 0-.11.013a2.354 2.354 0 0 1-1.661-.545 3.003 3.003 0 0 0 .984-2.494 3.044 3.044 0 0 0-.505-1.43 2.357 2.357 0 0 1 3.38 1.916Z"
      fill="currentColor"
    />
    <path
      d="M20.556 17.012c-.052.637-.46 1.188-1.142 1.562-.656.361-1.483.532-2.303.512.472-.427.748-.958.8-1.522.066-.814-.321-1.595-1.096-2.219a5.416 5.416 0 0 0-1.509-.827c1.45-.42 3.275-.137 4.397.768.604.486.912 1.096.853 1.726Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPeople;
