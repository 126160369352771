import { Auth, Hub } from "aws-amplify";
import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { create } from "zustand";
import { AUTH_PROVIDER } from "../constants";
import { AuthenticationContext } from "./AuthenticationContext";

type AwsAmplifyStatus = "initializing" | "authenticated" | "unauthenticated" | "error";
type AwsAmplifyState = {
  status: AwsAmplifyStatus;
  accessToken: string | null;
  refreshToken?: string;
  user: any | null;
  fetchCurrentSession: () => Promise<void>;
};

if (typeof window !== "undefined") {
  Auth.configure({
    identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
    region: "eu-central-1",
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
      scope: ["email", "openid", "profile"],
      redirectSignIn: process.env.NEXT_PUBLIC_COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.NEXT_PUBLIC_COGNITO_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
  });
}

export const awsAmplifyState = create<AwsAmplifyState>((set, get) => ({
  status: "initializing",
  accessToken: null,
  user: null,

  fetchCurrentSession: async () => {
    try {
      const session = await Auth.currentSession();
      // Check if the user is authenticated
      const user = await Auth.currentAuthenticatedUser();
      const accessToken = session.getIdToken().getJwtToken();
      const refreshToken = session.getRefreshToken().getToken();

      return set({
        status: "authenticated",
        refreshToken: refreshToken,
        accessToken: accessToken,
        user,
      });
    } catch (e) {
      // The Auth methods above throw an error when the user is not authenticated, so this is intended
      return set({
        status: "unauthenticated",
        accessToken: null,
      });
    }
  },
}));

export const useAwsAmplifyState = awsAmplifyState;

export const getApiAccessToken = () => awsAmplifyState.getState().accessToken;
export const getApiRefreshToken = () => awsAmplifyState.getState().user?.signInUserSession?.refreshToken?.token;

export const AwsAmplifyAuthenticationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { status, fetchCurrentSession } = useAwsAmplifyState(({ status, fetchCurrentSession }) => ({
    status,
    fetchCurrentSession,
  }));

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      Hub.listen("auth", ({ payload: { event, data } }) => {
        console.info("[AWS Cognito] ", event, data);
      });
    }
    fetchCurrentSession();
  }, []);

  // Fetch session when window/tab is re-focused
  useEffect(() => {
    const listener = () => {
      if (!document.hidden) {
        fetchCurrentSession();
      }
    };

    document.addEventListener("visibilitychange", listener);

    return () => {
      document.removeEventListener("visibilitychange", listener);
    };
  }, []);

  const signIn = useCallback(() => {
    Auth.federatedSignIn({ customProvider: AUTH_PROVIDER });
  }, []);

  const signOut = useCallback(() => {
    Auth.signOut();
  }, []);

  const providerValue = useMemo(
    () => ({
      status,
      signIn,
      signOut,
    }),
    [status, signIn, signOut]
  );

  return <AuthenticationContext.Provider value={providerValue}>{children}</AuthenticationContext.Provider>;
};
