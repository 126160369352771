import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { mockGoalFactory } from "../mockdata/goal";

export const goalHandlers = [
  rest.get(`${API_ENDPOINT}/app/goals`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockGoalFactory.goal.getAll(),
      })
    );
  }),
];
