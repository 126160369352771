import type { NextPage } from "next";
import React from "react";
import { ErrorPage } from "../components/pages/ErrorPage/ErrorPage";

type Props = {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
};

const Page: NextPage<Props> = () => {
  return (
    <ErrorPage
      messages={{
        meta: "errors:500.meta",
        title: "errors:500.title",
        code: "errors:500.code",
        back: "errors:500.back",
      }}
    />
  );
};
export default Page;
