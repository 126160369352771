import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { mockSkillFactory } from "../mockdata/skill";

export const skillHandlers = [
  rest.get(`${API_ENDPOINT}/app/skills`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockSkillFactory.skill.getAll(),
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/skills/statistics`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockSkillFactory.statistics.getAll(),
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/skills/rates`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockSkillFactory.rate.getAll(),
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/skills/:skill_id/rates`, (req, res, ctx) => {
    const { skill_id } = req.params;

    return res(
      ctx.status(200),
      ctx.json({
        data: mockSkillFactory.rate.findMany({
          where: {
            skill_id: {
              equals: Number(skill_id),
            },
          },
        }),
      })
    );
  }),
  rest.post(`${API_ENDPOINT}/app/skills/:student_id/rates`, (req, res, ctx) => {
    const { student_id } = req.params;
    mockSkillFactory.rate.update({
      where: {
        skill_id: {
          equals: 1,
        },
        student_id: {
          equals: Number(student_id),
        },
      },
      data: {
        completed: true,
      },
    });
    return res(
      ctx.status(200),
      ctx.delay(1200),
      ctx.json({
        status: 200,
        message: "Skill rate updated successfully",
      })
    );
  }),

  rest.delete(`${API_ENDPOINT}/app/skills/:student_id/rates`, (req, res, ctx) => {
    const { student_id } = req.params;

    mockSkillFactory.rate.update({
      where: {
        skill_id: {
          equals: 1, // req.url has encoded search params, so we can't get skill_id from it
        },
        student_id: {
          equals: Number(student_id),
        },
      },
      data: {
        completed: false,
      },
    });

    return res(
      ctx.status(200),
      ctx.delay(1200),
      ctx.json({
        status: 200,
        message: "Skill rate deleted successfully",
      })
    );
  }),
];
