import { SVGProps } from "react";
const SvgTune = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="b"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="currentColor" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#b)">
      <path
        d="M3.333 15.833a.804.804 0 0 1-.593-.24A.804.804 0 0 1 2.5 15c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24h3.334c.236 0 .434.08.594.24.16.16.239.357.239.593s-.08.434-.24.593a.807.807 0 0 1-.593.24H3.333Zm0-10a.806.806 0 0 1-.593-.239A.807.807 0 0 1 2.5 5c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24H10c.236 0 .434.08.594.24.16.16.24.358.24.594s-.08.434-.24.594a.809.809 0 0 1-.594.24H3.333ZM10 17.5a.804.804 0 0 1-.593-.24.804.804 0 0 1-.24-.593v-3.334c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.594.24c.16.16.24.357.24.593v.834h5.833c.236 0 .434.08.593.24.16.16.24.357.24.593s-.08.434-.24.593a.804.804 0 0 1-.593.24h-5.834v.834c0 .236-.08.434-.239.593a.807.807 0 0 1-.594.24Zm-3.333-5a.807.807 0 0 1-.595-.24.806.806 0 0 1-.239-.593v-.834h-2.5a.804.804 0 0 1-.593-.24A.804.804 0 0 1 2.5 10c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24h2.5v-.833c0-.236.08-.434.24-.594.16-.16.358-.239.594-.239s.434.08.594.24c.16.16.239.357.239.593v3.334c0 .236-.08.434-.24.593a.807.807 0 0 1-.593.24ZM10 10.833a.804.804 0 0 1-.593-.24.804.804 0 0 1-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24h6.667c.236 0 .434.08.593.24.16.16.24.358.24.594s-.08.434-.24.593a.804.804 0 0 1-.593.24H10ZM13.333 7.5a.804.804 0 0 1-.593-.24.804.804 0 0 1-.24-.593V3.333c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.594.24c.16.16.24.357.24.593v.834h2.5c.236 0 .434.08.593.239.16.16.24.358.24.594s-.08.434-.24.594a.806.806 0 0 1-.593.24h-2.5v.833c0 .236-.08.434-.24.593a.804.804 0 0 1-.594.24Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgTune;
