import { SVGProps } from "react";
const SvgTrendDown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path fill="currentColor" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M17 18a.965.965 0 0 1-.712-.288A.965.965 0 0 1 16 17c0-.283.096-.52.288-.712A.965.965 0 0 1 17 16h1.6l-5.2-5.15-3.3 3.3a.917.917 0 0 1-.312.2c-.125.05-.255.075-.388.075a.942.942 0 0 1-.375-.075 1.316 1.316 0 0 1-.325-.2L2.675 8.075A.918.918 0 0 1 2.4 7.4c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275L9.4 12l3.3-3.3c.1-.1.208-.171.325-.213.117-.041.242-.062.375-.062s.258.02.375.062a.883.883 0 0 1 .325.213l5.9 5.9V13c0-.283.096-.521.288-.713A.967.967 0 0 1 21 12c.283 0 .52.096.712.287.192.192.288.43.288.713v4c0 .283-.096.52-.288.712A.965.965 0 0 1 21 18h-4Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgTrendDown;
