import { SVGProps } from "react";
const SvgCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.04 17.234 9.863-10.08a.519.519 0 0 1 .738 0L22.85 8.39a.547.547 0 0 1 0 .754L11.397 20.846a.492.492 0 0 1-.716 0l-5.547-5.669c-.179-.183-.179-.526 0-.731l1.23-1.235c.202-.205.537-.205.716 0l3.96 4.023Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCheckmark;
