import { factory, primaryKey } from "@mswjs/data";
import { EducationLevel } from "../../types/EducationLevel";

export const mockMetaFactory = factory({
  meta: {
    id: primaryKey(Number),
    skill_levels: Array,
    education_levels: Array,
    authorisation: {
      permissions: Array,
      roles: Array,
    },
    user: {
      id: Number,
      name: String,
      email: String,
      display_name: String,
      family_name: String,
      given_name: String,
      is_readonly: Boolean,
      last_active: String,
      name_insertions: String,
    },
  },
});

export const metaData = {
  id: 1,
  statistics: {
    high_skill_percentage: 50,
    low_skill_percentage: 25,
  },
  getEducationLevelById: (educationLevels: EducationLevel[], educationLevelId: number | undefined) => {
    return educationLevels.find((educationLevel) => educationLevel.id === educationLevelId);
  },
  skill_levels: [
    {
      id: 1,
      label: "Net begonnen",
      level: 1,
    },
    {
      id: 2,
      label: "In ontwikkeling",
      level: 2,
    },
    {
      id: 3,
      label: "Al veel bereikt",
      level: 3,
    },
    {
      id: 4,
      label: "Klaar voor de volgende uitdaging",
      level: 4,
    },
  ],
  education_levels: [
    { id: 1, label: "Groen", color: "hsla(102, 52%, 55%, 1)", level: 1 },
    { id: 2, label: "Geel", color: "hsla(40, 100%, 67%, 1)", level: 2 },
    { id: 3, label: "Blauw", color: "hsla(220, 100%, 67%, 1)", level: 3 },
    { id: 4, label: "Basis", color: "hsla(175, 66%, 55%, 1)", level: 4 },
    { id: 5, label: "Kader", color: "hsla(102, 52%, 55%, 1)", level: 1 },
    { id: 6, label: "Theoretisch", color: "hsla(40, 100%, 67%, 1)", level: 2 },
    { id: 7, label: "Havo", color: "hsla(220, 100%, 67%, 1)", level: 3 },
    { id: 8, label: "Paars", color: "hsla(271, 45%, 55%, 1)", level: 8 },
  ],
  authorisation: {
    permissions: [
      { key: "rubric_show", name: "Weergeven", description: null },
      { key: "rubric_show_progress", name: "Voortgang inzien", description: null },
      { key: "assessment_round_index", name: "Beoordelingsrondes inzien", description: null },
      { key: "rubric_rate", name: "Beoordelen", description: null },
      { key: "rubric_rate_class", name: "Beoordeel klas", description: null },
      { key: "rubric_index", name: "Overzicht", description: null },
      { key: "rubric_create_goal", name: "Hoofdleerdoel aanmaken", description: null },
      { key: "rubric_create_subgoal", name: "Subleerdoel aanmaken", description: null },
      { key: "rubric_create_skill", name: "Skill aanmaken", description: null },
      { key: "rubric_edit_goal", name: "Hoofdleerdoel bewerken", description: null },
      { key: "rubric_edit_subgoal", name: "Subleerdoel bewerken", description: null },
      { key: "rubric_edit_skill", name: "Skill bewerken", description: null },
      { key: "rubric_delete_goal", name: "Hoofdleerdoel verwijderen", description: null },
      { key: "rubric_delete_subgoal", name: "Subleerdoel verwijderen", description: null },
      { key: "rubric_delete_skill", name: "Skill verwijderen", description: null },
      { key: "rubric_edit", name: "Planning aanpassen", description: null },
      { key: "class_index", name: "Overzicht", description: null },
      { key: "class_show", name: "Details", description: null },
      { key: "class_show_students", name: "Details", description: null },
      { key: "class_show_progress", name: "Voortgang inzien", description: null },
      { key: "cohort_create", name: "Aanmaken", description: null },
      { key: "cohort_index", name: "Overzicht", description: null },
      { key: "cohort_show", name: "Details", description: null },
      { key: "cohort_edit", name: "Bewerken", description: null },
      { key: "cohort_delete", name: "Verwijderen", description: null },
      { key: "subject_index", name: "Overzicht", description: null },
      { key: "subject_show", name: "Details", description: null },
      { key: "period_create", name: "Aanmaken", description: null },
      { key: "subject_show_classes", name: "Details", description: null },
      { key: "student_show", name: "Details", description: null },
      { key: "user_index", name: "Overzicht", description: null },
      { key: "user_detail", name: "Details", description: null },
      { key: "filter_index", name: "Overzicht", description: null },
      { key: "filter_create", name: "Aanmaken", description: null },
      { key: "filter_edit", name: "Bewerken", description: null },
      { key: "permission_index", name: "Overzicht", description: null },
      { key: "report_class_show", name: "Klassen", description: null },
      { key: "report_class_student_show", name: "Lesgroepen", description: null },
      { key: "report_student_show", name: "Leerlingen", description: null },
      { key: "report_teacher_class_show", name: "Docenten", description: null },
    ],
    roles: [
      {
        key: "admin",
        name: "Beheerder",
        description: null,
      },
      {
        key: "teacher",
        name: "Docent",
        description: null,
      },
      {
        key: "external_teacher",
        name: "Externe docent",
        description: null,
      },
    ],
  },
  user: {
    id: 1,
    is_impersonated: false,
    display_name: "Admin",
    family_name: "Admin",
    given_name: "Admin",
    is_readonly: false,
    last_active: "2021-03-31T09:00:00.000000Z",
    name_insertion: null,
    school_number: null,
    email: "admin@dij.digital",
  },
};
