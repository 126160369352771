import { SVGProps } from "react";
const SvgArrowCircleLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="c"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={16}
      height={16}
    >
      <path transform="rotate(-180 16 16)" fill="currentColor" d="M16 16h16v16H16z" />
    </mask>
    <g mask="url(#c)">
      <path
        d="M7.133 4.483a.632.632 0 0 1 .192.484.69.69 0 0 1-.209.483L5.233 7.333h7.433c.19 0 .348.064.476.192a.645.645 0 0 1 .191.475.647.647 0 0 1-.191.475.647.647 0 0 1-.476.192H5.233l1.9 1.9a.65.65 0 0 1 .2.475.649.649 0 0 1-.2.475.65.65 0 0 1-.475.2.649.649 0 0 1-.475-.2l-3.05-3.05a.589.589 0 0 1-.142-.217A.743.743 0 0 1 2.95 8c0-.089.013-.172.041-.25a.589.589 0 0 1 .142-.217L6.2 4.467a.621.621 0 0 1 .458-.184.65.65 0 0 1 .475.2Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgArrowCircleLeft;
