import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { mockClassFactory } from "../mockdata/class";

export const periodsHandlers = [
  rest.get(`${API_ENDPOINT}/app/periods`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockClassFactory.periods.getAll(),
      })
    );
  }),
];
