import { SVGProps } from "react";
const SvgChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path transform="rotate(-90 0 24)" fill="currentColor" d="M0 24h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M14.95 12a1.1 1.1 0 0 1-.063.375.871.871 0 0 1-.212.325l-4.625 4.625a.891.891 0 0 1-.688.262.975.975 0 0 1-.687-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l3.9-3.9L8.65 8.075a.894.894 0 0 1-.263-.688.978.978 0 0 1 .288-.687.948.948 0 0 1 .7-.275c.284 0 .517.092.7.275l4.6 4.6c.1.1.17.208.212.325a1.1 1.1 0 0 1 .063.375Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgChevronRight;
