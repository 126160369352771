import { SVGProps } from "react";
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M15 27c6.627 0 12-5.373 12-12S21.627 3 15 3 3 8.373 3 15s5.373 12 12 12ZM14 9h2v7h-2V9Zm1 9.5a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 15 18.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgWarning;
