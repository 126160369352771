import { QueryCache, QueryClient } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import { ErrorResponse, handleApiError } from "./api";

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: async (error: any) => {
        return handleApiError({
          msg: "Er is iets misgegaan",
        })(error as ErrorResponse);
      },
    },
    queries: {
      onError: async (error: any) => {
        return handleApiError({
          msg: "Er is iets misgegaan",
        })(error as ErrorResponse);
      },
      staleTime: Infinity,
      retry: 1,
    },
  },
  queryCache: new QueryCache({
    onError: async (error, query) => {
      const user = await Auth.currentAuthenticatedUser();
      if (!user) {
        // User is not authenticated, refresh session or redirect to login
        const session = await Auth.currentSession();

        if (!session.isValid()) {
          await Auth.signOut();
        }

        return;
      }
    },
  }),
});
