import { useEducationColors } from "./useEducationColors";

export const ColorMapper: React.FC = () => {
  const { renderCSSVariables } = useEducationColors();

  return (
    <style data-testid="colormapper" type="text/css">{`
/* Generated by src/components/atoms/ColorsMapper.tsx */
:root {
${renderCSSVariables()}
}
  `}</style>
  );
};
