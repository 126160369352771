import { SVGProps } from "react";
const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M7.963 12c.262 0 .484-.09.666-.272a.905.905 0 0 0 .271-.665.905.905 0 0 0-.271-.666.906.906 0 0 0-.666-.272.906.906 0 0 0-.667.272.905.905 0 0 0-.271.665c0 .263.09.485.271.666a.906.906 0 0 0 .667.272Zm.112-7.725c.35 0 .631.097.844.29a.98.98 0 0 1 .319.76c0 .212-.072.428-.216.646a3.671 3.671 0 0 1-.61.685c-.375.325-.65.638-.825.938-.175.3-.262.6-.262.9 0 .175.066.322.197.44a.678.678 0 0 0 .928-.009.839.839 0 0 0 .262-.469 1.41 1.41 0 0 1 .254-.59c.131-.181.347-.416.647-.704.387-.362.659-.693.816-.993.155-.3.233-.631.233-.994 0-.638-.24-1.16-.721-1.566C9.459 3.203 8.837 3 8.075 3c-.525 0-.99.1-1.396.3-.407.2-.723.506-.948.919a.743.743 0 0 0-.093.478.56.56 0 0 0 .262.384.75.75 0 0 0 1.012-.225 1.42 1.42 0 0 1 .498-.431c.193-.1.415-.15.665-.15ZM8 15a7.253 7.253 0 0 1-2.906-.591 7.678 7.678 0 0 1-2.39-1.603 7.487 7.487 0 0 1-1.613-2.381A7.3 7.3 0 0 1 .5 7.5a7.3 7.3 0 0 1 .591-2.925 7.487 7.487 0 0 1 1.613-2.381A7.689 7.689 0 0 1 5.094.59 7.26 7.26 0 0 1 8 0c1.05 0 2.031.197 2.944.59a7.579 7.579 0 0 1 2.381 1.604 7.445 7.445 0 0 1 1.594 2.381c.387.912.581 1.888.581 2.925a7.405 7.405 0 0 1-.581 2.925 7.446 7.446 0 0 1-1.594 2.381 7.568 7.568 0 0 1-2.381 1.603A7.346 7.346 0 0 1 8 15Zm0-1.5c1.675 0 3.094-.584 4.256-1.753C13.42 10.578 14 9.162 14 7.5s-.581-3.078-1.744-4.247C11.094 2.084 9.675 1.5 8 1.5c-1.638 0-3.047.584-4.229 1.753C2.591 4.422 2 5.838 2 7.5s.59 3.078 1.772 4.247C4.953 12.916 6.361 13.5 8 13.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHelp;
