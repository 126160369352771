import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { mockFilterFactory } from "../mockdata/filter";

export const filterHandlers = [
  rest.get(`${API_ENDPOINT}/app/filters`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockFilterFactory.filter.getAll(),
      })
    );
  }),
  rest.post(`${API_ENDPOINT}/app/filters`, async (req, res, ctx) => {
    const payload = await req.json();
    const id = Math.floor(Math.random() * 1000);
    return res(
      ctx.status(201),
      ctx.delay(3000),
      ctx.json({
        status: 200,
        message: "Filter created successfully",
        data: mockFilterFactory.filter.create({ id, ...payload }),
      })
    );
  }),
];
