import { SVGProps } from "react";
const SvgChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path transform="rotate(90 24 0)" fill="currentColor" d="M24 0h24v24H24z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M9.05 12a1.1 1.1 0 0 1 .063-.375.871.871 0 0 1 .212-.325l4.625-4.625a.89.89 0 0 1 .688-.262.975.975 0 0 1 .687.287.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-3.9 3.9 3.925 3.925a.894.894 0 0 1 .263.688.979.979 0 0 1-.288.687.949.949 0 0 1-.7.275.948.948 0 0 1-.7-.275l-4.6-4.6a.871.871 0 0 1-.212-.325A1.1 1.1 0 0 1 9.05 12Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgChevronLeft;
