import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

export const BugsnagClient = Bugsnag.start({
  appType: "web",
  appVersion: process.env.NEXT_PUBLIC_VERSION,
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ["production", "staging", "acceptance", "test"],
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || "random_key_so_bugsnag_doesnt_error_locally",
  plugins: [new BugsnagPluginReact(React)],
  onError: (report) => {
    if (process.env.NODE_ENV === "development") {
      console.error(report);
      return false;
    }
    if (report.originalError === "Network Error") {
      return false;
    }
  },
});
export const notifyError = (error: Error) => {
  BugsnagClient.notify(error, (event) => {
    event.errors[0].errorClass = error.name;
    event.errors[0].errorMessage = error.message;
  });
};

export const ErrorBoundary = BugsnagClient.getPlugin("react")!.createErrorBoundary(React);
