import React from "react";

export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  PLAIN = "plain",
  GHOST = "ghost",
  DANGER = "danger",
  OUTLINE = "outline",
  PRIMARY_OUTLINE = "primaryOutline",
  SECONDARY_OUTLINE = "secondaryOutline",
  DANGER_OUTLINE = "dangerOutline",
}

export enum ButtonSize {
  XSMALL = "xsmall",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  loading?: boolean;
}
