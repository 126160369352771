import * as d3 from "d3";
import { ReportDataPointMetaItem, ReportResponse } from "../../types/Report";

export const mockYFunction = (width: number, padding: number) => {
  return d3
    .scaleLinear()
    .domain([0, 100])
    .range([width - padding, 0 + padding]);
};

export const mockXFunction = (width: number, padding: number) => {
  return d3
    .scaleUtc()
    .domain([new Date(2023, 9, 1), new Date(2024, 2, 15)])
    .range([0 + padding, width - padding]);
};

// Return a copy of mockReportData with randomized datapoints
export const mockReportDataRandomized = (input: ReportResponse) => {
  const mockReportDataCopy: ReportResponse = JSON.parse(JSON.stringify(input));
  mockReportDataCopy.data.forEach((student) => {
    student.datapoints.forEach((datapoint, index) => {
      if (index === 0) {
        datapoint.progress_percentage = Math.floor(Math.random() * 10);
      } else {
        datapoint.progress_percentage = Math.floor(
          Math.random() * (100 / (student.datapoints.length / 2)) + student.datapoints[index - 1].progress_percentage
        );
        datapoint.progress_percentage = Math.min(100, datapoint.progress_percentage);
      }
    });

    // calc average datapoints as average from all student endpoints
    mockReportDataCopy.averages![0].datapoints.forEach((datapoint, index) => {
      const studentAverage =
        mockReportDataCopy.data.reduce((acc, student) => {
          return acc + student.datapoints[index].progress_percentage;
        }, 0) / mockReportDataCopy.data.length;
      datapoint.progress_percentage = studentAverage;
    });

    // bandwidths
    mockReportDataCopy.bandwidth_bg!.forEach((bandwidth, index) => {
      if (index === 0) {
        bandwidth.progress_percentage = Math.floor(Math.random() * 10);
        mockReportDataCopy.bandwidth_fg![index].progress_percentage = bandwidth.progress_percentage;
      } else {
        bandwidth.progress_percentage = Math.floor(70 + Math.random() * 30);
        mockReportDataCopy.bandwidth_fg![index].progress_percentage = bandwidth.progress_percentage;
      }
    });

    const randomBgMargin = Math.floor(20 + Math.random() * 40);
    mockReportDataCopy.bandwidth_bg![0].margin = randomBgMargin;
    mockReportDataCopy.bandwidth_bg![1].margin = randomBgMargin;

    const randomFgMargin = Math.floor(5 + Math.random() * 5);
    mockReportDataCopy.bandwidth_fg![0].margin = randomFgMargin;
    mockReportDataCopy.bandwidth_fg![1].margin = randomFgMargin;

    // swimlane year 2
    mockReportDataCopy.swimlanes![1].progress_percentage = Math.floor(20 + Math.random() * 40);
  });
  return mockReportDataCopy;
};

export const getRandomMetaItems = (totalPercentage: number) => {
  const items: ReportDataPointMetaItem[] = [];

  let toDividePercentage = totalPercentage * 3;

  [
    { name: "Geschiedenis", id: "5" },
    { name: "Frans", id: "8" },
    { name: "Wiskunde", id: "2" },
  ].forEach((subject) => {
    let percentage = Math.floor(Math.random() * toDividePercentage);
    percentage = Math.min(percentage, 100);
    percentage = Math.floor(Math.max(toDividePercentage * 0.33, percentage));

    if (subject.name === "Wiskunde") {
      percentage = toDividePercentage;
    }

    const randomIndicator = Math.floor(Math.random() * 3);
    const indicator = ["ahead", "behind", "on-track"][randomIndicator] as "ahead" | "behind" | "average";

    toDividePercentage -= percentage;
    items.push({
      id: subject.id,
      type: "subject",
      title: subject.name,
      progress_percentage: percentage,
      progress_indicator: indicator,
    });
  });

  return items;
};
