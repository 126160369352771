import { factory, primaryKey } from "@mswjs/data";

export const mockSkillFactory = factory({
  skill: {
    id: primaryKey(Number),
    goal_id: Number,
    period_id: Number,
    description: String,
    additional_description: String,
    vertical_order: Number,
    horizontal_order: Number,
  },
  rate: {
    id: primaryKey(Number),
    student_id: Number,
    skill_id: Number,
    completed: Boolean,
  },
  statistics: {
    id: primaryKey(Number),
    total: Number,
    completed: Number,
  },
});

export const skillData = [
  {
    id: 1,
    goal_id: 16,
    description: "Ik weet wat het gemiddelde is.",
    additional_description:
      '[{"attributes":{"italic":true},"insert":"Extra"},{"insert":" beschrijving van een "},{"attributes":{"bold":true},"insert":"skillset"},{"insert":" o.b.v. "},{"attributes":{"link":"https://cdpn.io/cpe/boomboom/quilljs.com"},"insert":"Quill"}]',
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 2,
    goal_id: 16,
    description: "Ik kan het gemiddelde van een reeks getallen berekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 3,
    goal_id: 16,
    description: "Ik kan het gewogen gemiddelde berekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 4,
    goal_id: 16,
    description:
      "Ik kan vanuit een tabel of een reeks getallen het gemiddelde berekenen. Ik weet welke methode ik moet toepassen. Ik kan dit ook als er negatieve getallen bij staan.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 5,
    goal_id: 17,
    description: "Ik weet hij je een turftabel maakt.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 6,
    goal_id: 17,
    description: "Ik kan een wegendiagram en een boomdiagram aflezen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 7,
    goal_id: 17,
    description: "Ik kan zelf een eenvoudig wegendiagram en boomdiagram maken.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 8,
    goal_id: 17,
    description: "Ik kan verschillende eenvoudige soorten diagrammen tekenen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 9,
    goal_id: 18,
    description: "Ik weet wat een rooster is",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 10,
    goal_id: 18,
    description: "Ik kan zelf een eenvoudig rooster maken",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 11,
    goal_id: 18,
    description: "Ik kan uit een rooster, boomdiagram en wegendiagram verschillende combinaties halen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 12,
    goal_id: 18,
    description: "Ik kan uit deze diagrammen ook de juiste conclusies trekken.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 13,
    goal_id: 19,
    description: "Ik weet wat  een kans is.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 14,
    goal_id: 19,
    description: "Ik kan uit een rooster, wegendiagram en boomdiagram het totaal aantal combinaties berekenen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 15,
    goal_id: 19,
    description:
      "Ik weet hoe ik kansen op een gevraagde uitkomst of combinatie moet berekenen uit een boom- en wegendiagram",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 16,
    goal_id: 19,
    description: "Ik weet ook hoe ik moet bij een eenvoudig rooster kansen moet berekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 21,
    goal_id: 21,
    description: "Ik kan hoeken meten en tekenen met een kompasroos en/of geodriehoek.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 22,
    goal_id: 21,
    description:
      "Ik weet wat de hoekensom van een cirkel, vierhoek en driehoek is. \nIk kan in deze figuren hoeken berekenen met behulp van de hoekensom.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 23,
    goal_id: 21,
    description: "Ik kan hoeken berekenen door gebruik te maken van de symmetrie en overstaande hoeken.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 24,
    goal_id: 21,
    description: "Ik kan ook de coordinaten  (x,y,) aflezen en tekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 901,
    goal_id: 21,
    description: "Ik weet wat evenwijdig, loodrecht en een gestrekte hoek is",
    period_id: 1,
    vertical_order: 2,
    horizontal_order: 1,
  },
  {
    id: 902,
    goal_id: 21,
    description: "Ik weet wat een koershoek is en kan deze ook tekenen en meten.",
    period_id: 1,
    vertical_order: 2,
    horizontal_order: 2,
  },
  {
    id: 903,
    goal_id: 21,
    description: "Ik kan evenwijdige lijnen en rechte hoeken tekenen",
    period_id: 1,
    vertical_order: 2,
    horizontal_order: 3,
  },
  {
    id: 25,
    goal_id: 22,
    description:
      "Ik ken de eigenschappen (zoals even lang, evenwijdig) en alle namen van de vlakke figuren (vierkant, rechthoek, driehoek, cirkel, parallellogram, ruit, vlieger) en kan deze figuren ook herkennen. ",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 26,
    goal_id: 22,
    description:
      "Ik ken de eigenschappen van de verschillende driehoeken (rechthoekige, gelijkzijdige en gelijkbenige driehoek) en kan deze driehoeken ook herkennen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 27,
    goal_id: 22,
    description:
      "Ik kan een rechthoekige driehoek op ware grootte of op schaal tekenen. Ik kan hierbij gebruik maken van een koershoekmeter en/of geodriehoek.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 28,
    goal_id: 22,
    description: "Ik kan ook een rechthoek en vierkant op schaal tekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 29,
    goal_id: 23,
    description: "Ik ken de verschillende vormen van symmetrie en kan deze ook herkennen in vlakke figuren",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 30,
    goal_id: 23,
    description: "Ik kan bij een draaisymmetrisch figuur de kleinste draaihoek berekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 31,
    goal_id: 23,
    description:
      "Ik kan in een figuur herkennen dat het niet symmetrisch is en het figuur z\u00f3 aanpassen dat het wel symmetrisch wordt (draai- spiegel en/of schuifsymmetrisch).",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 32,
    goal_id: 23,
    description: "Ik kan ook een simpel figuur spiegelen in een lijn door gebruik te maken van een geodriehoek",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 33,
    goal_id: 24,
    description: "Ik kan de oppervlakte en de omtrek van een vierkant en rechthoek berekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 34,
    goal_id: 24,
    description: "Ik kan de oppervlakte en de omtrek van een driehoek berekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 35,
    goal_id: 24,
    description:
      "Ik kan de oppervlakte van eenvoudige samengestelde vlakke figuren berekenen. Ik kan dit met inlijsten en door het figuur te verdelen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 36,
    goal_id: 24,
    description:
      "Ik kan de omtrek en oppervlakte van eenvoudige samengestelde vlakke figuren berekenen. Ik kan dit met inlijsten en door het figuur te verdelen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 41,
    goal_id: 26,
    description:
      "Ik ken de eigenschappen en namen van de ruimtefiguren: kubus, balk, bol, kegel, piramide en cilinder) en kan deze ook herkennen. ",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 42,
    goal_id: 26,
    description:
      "Ik ken de eigenschappen en namen van de ruimtefiguren: prisma en cilinder en kan deze ook herkennen. ",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 43,
    goal_id: 26,
    description: "Ik kan het grondvlak aangeven in de hiervoor genoemde ruimtefiguren.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 44,
    goal_id: 26,
    description: "Ik kan ook de uitslag van een een kubus en een balk (op schaal) tekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 45,
    goal_id: 27,
    description: "Ik kan de inhoud van een balk en kubus berekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 46,
    goal_id: 27,
    description: "Ik kan de oppervlakte van een kubus berekenen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 47,
    goal_id: 27,
    description: "Ik kan de oppervlakte van een balk berekenen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 49,
    goal_id: 28,
    description: "Ik weet wat een aanzicht is en ken ook de verschillende soorten aanzichten.\n",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 50,
    goal_id: 28,
    description: "Ik kan van een simpel figuur het voor-, achter-, boven-, onder- en zij aanzicht herkennen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 51,
    goal_id: 28,
    description:
      "Ik kan van een simpel figuur het voor-, achter-, boven-, onder- en zij aanzicht tekenen op een gegeven schaal.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 52,
    goal_id: 28,
    description:
      "Ik kan ook bouwtekeningen en plattegronden gebruiken en herken hier ook de verschillende aanzichten in.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 53,
    goal_id: 29,
    description: "Ik weet wat een doorsnede is.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 54,
    goal_id: 29,
    description: "Ik kan een doorsnede tekenen van een kubus.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 55,
    goal_id: 29,
    description: "Ik kan een doorsnede tekenen van een balk",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 56,
    goal_id: 29,
    description: "Ik kan deze doorsneden ook op schaal tekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 57,
    goal_id: 30,
    description: "Ik weet wat kijklijnen zijn.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 58,
    goal_id: 30,
    description: "Ik kan kijklijnen tekenen met een geodriehoek/liniaal",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 59,
    goal_id: 30,
    description: "Ik weet wat een kijkhoek is en kan deze tekenen/meten.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 60,
    goal_id: 30,
    description:
      "Ik kan ook kijklijnen gebruiken om de juiste plaats te bepalen en ik kan ook kijklijnen gebruiken om de kijkhoek te bepalen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 61,
    goal_id: 31,
    description:
      "Ik weet wat eenheden van lengte en gewicht zijn en kan ook allerlei eenheden van lengte en gewicht omrekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 62,
    goal_id: 31,
    description:
      "Ik weet wat eenheden van oppervlakte en inhoud zijn en kan ook allerlei verschillende eenheden van oppervlakte en inhoud omrekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 63,
    goal_id: 31,
    description: "Ik weet wat eenheden van tijd zijn en kan rekenen met verschillende eenheden van tijd. ",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 64,
    goal_id: 31,
    description:
      "Ik weet ook wat eenheden van snelheid zijn en kan van m/s naar km/uur en andersom rekenen.\nIk kan ook de afstand, tijd en de snelheid berekenen en weet ook de juiste eenheden erbij te gebruiken.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 65,
    goal_id: 32,
    description: "Ik weet hoe ik moet op hele getallen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 66,
    goal_id: 32,
    description: "Ik weet hoe ik moet afronden bij decimale getallen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 67,
    goal_id: 32,
    description: "Ik weet hoe ik op de juiste manier moet afronden bij geld, procenten en mensen/dingen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 68,
    goal_id: 32,
    description:
      "Ik kan ook in een willekeurige opgave aangeven hoe ik moet afronden.\nIk weet hoe ik moet afronden op bijvoorbeeld duizendtallen, honderdtallen, miljoenen enz.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 69,
    goal_id: 33,
    description: "Ik weet wat vuistregels zijn en kan deze ook toepassen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 70,
    goal_id: 33,
    description:
      "Ik kan vuistregels toepassen in verhoudingstabellen en ik kan ook schatting maken over hoeveelheden en afmetingen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 71,
    goal_id: 33,
    description: "Ik kan vuistregels ook toepassen in verhoudingstabellen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 72,
    goal_id: 33,
    description: "Ik kan ook uitkomsten van tevoren schatten en daarmee mijn antwoord(en) controleren",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 73,
    goal_id: 34,
    description: "Ik weet wat een macht en wortel is. ",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 74,
    goal_id: 34,
    description: "Ik weet hoe ik kwadraten en wortels in een rekenmachine moet invoeren. ",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 75,
    goal_id: 34,
    description: "Ik kan rekenen met grote getallen (miljoenen, miljarden) ",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 76,
    goal_id: 34,
    description:
      "Ik weet ook hoe ik kwadraten en wortels in een rekenmachine moet invoeren. Ik kan dit ook herkennen in een opgave. Ik kan ook opgaven met grote getallen berekenen en weet hoe ik hierbij mijn rekenmachine moet gebruiken.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 77,
    goal_id: 35,
    description: "Ik weet wat een schaal is en kan deze ook herkennen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 78,
    goal_id: 35,
    description: "Ik kan ook de werkelijke afstand berekenen als de schaal bekend is.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 79,
    goal_id: 35,
    description: "Ik kan ook zelf de schaal van een foto/tekening berekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 80,
    goal_id: 35,
    description:
      "Ik kan ook in diverse opgaven schaal herkennen en rekenen met een gegeven schaal.\nIk kan eenvoudige vormen tekenen op schaal.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 81,
    goal_id: 36,
    description: "Ik kan een lineair verband herkennen aan de formule.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 82,
    goal_id: 36,
    description: "Ik kan in een willekeurige lineaire formule aangeven wat het startgetal en stijg- of daalgetal.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 83,
    goal_id: 36,
    description:
      "Ik kan uit een verhaal aangeven wat het startgetal en wat het stijg- of daalgetal is en kan de juiste woordformule opstellen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 84,
    goal_id: 36,
    description: "Ik kan uit een lineaire grafiek een woordformule opstellen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 909,
    goal_id: 36,
    description: "Ik kan een lineair verband herkennen in een tabel met bovenin de tabel stappen van 1. ",
    period_id: 1,
    vertical_order: 2,
    horizontal_order: 1,
  },
  {
    id: 910,
    goal_id: 36,
    description:
      "Ik kan een lineair verband herkennen in een tabel als de stappen bovenin de tabel allemaal gelijk zijn.",
    period_id: 1,
    vertical_order: 2,
    horizontal_order: 2,
  },
  {
    id: 911,
    goal_id: 36,
    description:
      "Ik kan uit een tabel met gelijke stappen en waar het startgetal in staat, een lineaire formule opstellen.",
    period_id: 1,
    vertical_order: 2,
    horizontal_order: 3,
  },
  {
    id: 912,
    goal_id: 36,
    description:
      "Ik kan uit elke tabel met gelijke stappen een lineaire formule opstellen, ook als het begingetal niet in de tabel staat.",
    period_id: 1,
    vertical_order: 2,
    horizontal_order: 4,
  },
  {
    id: 913,
    goal_id: 36,
    description:
      "Ik weet wat een assenstelsel en coordinaten zijn en hoe een lineaire grafiek er uit ziet en kan deze herkennen.",
    period_id: 1,
    vertical_order: 3,
    horizontal_order: 1,
  },
  {
    id: 914,
    goal_id: 36,
    description: "Ik weet wat maximum en minimum is en kan deze ook aangeven in een grafiek.",
    period_id: 1,
    vertical_order: 3,
    horizontal_order: 2,
  },
  {
    id: 915,
    goal_id: 36,
    description:
      "Ik kan bij een lineaire formule en/of tabel in een gegeven assenstelsel, met getallen bij de x-as en y-as, de grafiek tekenen. ",
    period_id: 1,
    vertical_order: 3,
    horizontal_order: 3,
  },
  {
    id: 916,
    goal_id: 36,
    description:
      "Ik kan bij een lineaire formule en/of tabel in een gegeven assenstelsel, met getallen bij de x-as , de grafiek tekenen en de juiste getallen bij de y-as zetten. ",
    period_id: 1,
    vertical_order: 3,
    horizontal_order: 4,
  },
  {
    id: 85,
    goal_id: 37,
    description: "Ik weet wat machten en wortels zijn en hoe ik dit met mijn rekenmachine moet berekenen. ",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 86,
    goal_id: 37,
    description: "Ik kan eenvoudige formules met wortels en machten gebruiken en hierbij een gegeven tabel invullen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 87,
    goal_id: 37,
    description: "Ik kan een gegeven formule met een wortel of een macht de grafiek netjes tekenen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 88,
    goal_id: 37,
    description:
      "Ik kan ook van een wortelverband zelf een tabel maken en de grafiek tekenen in een zelf gekozen assenstelsel.\nIk kan hieruit ook eenvoudige conclusies trekken.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 89,
    goal_id: 38,
    description: "Ik weet wat inklemmen is en kan dit gebruiken als de uitkomst een geheel getal is.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 90,
    goal_id: 38,
    description: "Ik weet hoe ik dit netjes moet opschrijven.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 91,
    goal_id: 38,
    description: "Ik kan een lineaire vergelijking oplossen met inklemmen door gebruik te maken van grafiek(en)",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 92,
    goal_id: 38,
    description: "In een willekeurige opgave kan ik herkennen dat ik dit moet toepassen.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
  {
    id: 905,
    goal_id: 271,
    description:
      "Ik weet hoe ik van een eenvoudige breuk een percentage kan maken. \nIK weet hoe ik een verhoudingstabel moet gebruiken",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 1,
  },
  {
    id: 906,
    goal_id: 271,
    description: "Ik weet hoe ik een deel van het geheel moet berekenen",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 2,
  },
  {
    id: 907,
    goal_id: 271,
    description: "Ik weet hoe je nieuwe waarde met procentuele stijging/daling berekent.",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 3,
  },
  {
    id: 908,
    goal_id: 271,
    description: "Ik weet ook hoe je een procentuele toename/afname berekent\n",
    period_id: 1,
    vertical_order: 1,
    horizontal_order: 4,
  },
];

export const skillRateData = [
  {
    id: 1,
    skill_id: 1,
    student_id: 2,
    completed: true,
  },
];
