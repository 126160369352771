import { Icon } from "./Icon";

export { default as Acute } from "./Acute";
export { default as Add } from "./Add";
export { default as AddCircle } from "./AddCircle";
export { default as Alarm } from "./Alarm";
export { default as ArrowCircleLeft } from "./ArrowCircleLeft";
export { default as Calendar } from "./Calendar";
export { default as CalendarMonth } from "./CalendarMonth";
export { default as Checkmark } from "./Checkmark";
export { default as ChevronDown } from "./ChevronDown";
export { default as ChevronLeft } from "./ChevronLeft";
export { default as ChevronRight } from "./ChevronRight";
export { default as ChevronUp } from "./ChevronUp";
export { default as Close } from "./Close";
export { default as CloseMenu } from "./CloseMenu";
export { default as Dashboard } from "./Dashboard";
export { default as Delete } from "./Delete";
export { default as Drag } from "./Drag";
export { default as Edit } from "./Edit";
export { default as Error } from "./Error";
export { default as Eye } from "./Eye";
export { default as FilledChevronDown } from "./FilledChevronDown";
export { default as FilledChevronUp } from "./FilledChevronUp";
export { default as Help } from "./Help";
export { default as Home } from "./Home";
export { default as HorizontalDrag } from "./HorizontalDrag";
export { default as Info } from "./Info";
export { default as Late } from "./Late";
export { default as Locked } from "./Locked";
export { default as NewTab } from "./NewTab";
export { default as OpenMenu } from "./OpenMenu";
export { default as People } from "./People";
export { default as Question } from "./Question";
export { default as Roles } from "./Roles";
export { default as Rubrics } from "./Rubrics";
export { default as Search } from "./Search";
export { default as Settings } from "./Settings";
export { default as SubjectGroup } from "./SubjectGroup";
export { default as TrendDown } from "./TrendDown";
export { default as TrendFlat } from "./TrendFlat";
export { default as TrendUp } from "./TrendUp";
export { default as Tune } from "./Tune";
export { default as Unlocked } from "./Unlocked";
export { default as User } from "./User";
export { default as Users } from "./Users";
export { default as VerticalDrag } from "./VerticalDrag";
export { default as Warning } from "./Warning";
export { default as Messages } from "./Messages";
export { default as Cohort } from "./Cohort";
export { default as Dash } from "./Dash";
export { default as ThreeDots } from "./ThreeDots";
export { default as Group } from "./Group";

export default Icon;
