import { factory, primaryKey } from "@mswjs/data";

export const mockSubjectFactory = factory({
  subject: {
    id: primaryKey(Number),
    name: String,
  },
});

// Seed
export const subjectData = [
  {
    id: 1,
    name: "Wiskunde",
  },
  {
    id: 2,
    name: "Nederlands",
  },
  {
    id: 3,
    name: "Engels",
  },
];
