import { SVGProps } from "react";
const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path transform="rotate(-180 24 24)" fill="currentColor" d="M24 24h24v24H24z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M12 9.05a1.1 1.1 0 0 1 .375.063.871.871 0 0 1 .325.212l4.625 4.625c.183.183.27.413.262.688a.975.975 0 0 1-.287.687.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-3.9-3.9-3.925 3.925a.894.894 0 0 1-.688.263.979.979 0 0 1-.687-.288.948.948 0 0 1-.275-.7c0-.283.091-.517.275-.7l4.6-4.6c.1-.1.208-.17.325-.212A1.1 1.1 0 0 1 12 9.05Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgChevronUp;
