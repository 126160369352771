import { SVGProps } from "react";
const SvgCloseMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" {...props}>
    <path
      fill="currentColor"
      d="M4 18a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 17c0-.283.096-.52.288-.712A.967.967 0 0 1 4 16h11a.97.97 0 0 1 .713.288c.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 15 18H4Zm0-5a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 12a.97.97 0 0 1 .288-.713A.967.967 0 0 1 4 11h8c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 12 13H4Zm0-5a.968.968 0 0 1-.712-.287A.968.968 0 0 1 3 7a.97.97 0 0 1 .288-.713A.968.968 0 0 1 4 6h11c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 15 8H4Zm13.4 4 2.9 2.9a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-3.6-3.6a.96.96 0 0 1-.3-.7c0-.267.1-.5.3-.7l3.6-3.6a.948.948 0 0 1 .7-.275.95.95 0 0 1 .7.275.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L17.4 12Z"
    />
  </svg>
);
export default SvgCloseMenu;
