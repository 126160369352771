import { assessmentRoundData, mockAssessmentRoundFactory } from "./mockdata/assessmentRounds";
import {
  classData,
  classGoalsData,
  classPeriodsData,
  classStatisticsData,
  classStudentsData,
  mockClassFactory,
} from "./mockdata/class";
import { classesData, mockClassesFactory } from "./mockdata/classes";
import { cohortData, mockCohortFactory } from "./mockdata/cohort";
import { dashboardData, mockDashboardFactory } from "./mockdata/dashboard";
import { filterData, mockFilterFactory } from "./mockdata/filter";
import { goalData, mockGoalFactory } from "./mockdata/goal";
import { metaData, mockMetaFactory } from "./mockdata/meta";
import { mockReportFactory, mockReportFiltersData } from "./mockdata/report";
import { mockSkillFactory, skillData, skillRateData } from "./mockdata/skill";
import { mockSubjectFactory, subjectData } from "./mockdata/subject";

export const mockSeed = () => {
  if (mockMetaFactory.meta.getAll().length !== 0) {
    return;
  }

  // Seed meta data
  mockMetaFactory.meta.create(metaData);

  // Seed class data
  classData.forEach((classData) => {
    mockClassFactory.class.create(classData);
  });
  classGoalsData.forEach((classGoalsData) => {
    mockClassFactory.goals.create(classGoalsData);
  });

  classStatisticsData.forEach((classStatisticsData) => {
    mockClassFactory.statistics.create(classStatisticsData);
  });

  classPeriodsData.forEach((classPeriodsData) => {
    mockClassFactory.periods.create(classPeriodsData);
  });

  classStudentsData.forEach((classStudentsData) => {
    mockClassFactory.students.create(classStudentsData);
  });

  // Seed goal data
  goalData.forEach((goalData) => {
    mockGoalFactory.goal.create(goalData);
  });

  // Seed subject data
  subjectData.forEach((subjectData) => {
    mockSubjectFactory.subject.create(subjectData);
  });

  // Seed skill data
  skillData.forEach((skillData) => {
    mockSkillFactory.skill.create(skillData);
  });
  skillRateData.forEach((skillRateData) => {
    mockSkillFactory.rate.create(skillRateData);
  });

  // Seed Cohort data
  cohortData.forEach((cohortData) => {
    mockCohortFactory.cohort.create(cohortData);
  });

  // Seed assessment round data
  assessmentRoundData.forEach((assessmentRound) => {
    mockAssessmentRoundFactory.assessmentRound.create(assessmentRound);
  });

  // Seed filter data
  filterData.forEach((filterData) => {
    mockFilterFactory.filter.create(filterData);
  });

  // Seed dashboard data
  dashboardData.forEach((dashboardData) => {
    mockDashboardFactory.classes.create(dashboardData);
  });

  // Seed classes
  classesData.forEach((classData) => {
    mockClassesFactory.classes.create(classData);
  });

  // Seed report
  mockReportFiltersData.forEach((reportFilterData) => {
    mockReportFactory.filters.create(reportFilterData);
  });
};
