import { rest } from "msw";
import { API_ENDPOINT } from "../../constants";
import { mockCohortFactory } from "../mockdata/cohort";

export const cohortsHandlers = [
  rest.get(`${API_ENDPOINT}/app/cohorts`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: mockCohortFactory.cohort.getAll(),
      })
    );
  }),
  rest.get(`${API_ENDPOINT}/app/cohorts/:cohort_id`, (req, res, ctx) => {
    const { cohort_id } = req.params;

    const cohort = mockCohortFactory.cohort.findFirst({
      where: {
        id: {
          equals: Number(cohort_id),
        },
      },
    });

    if (cohort) {
      return res(
        ctx.status(200),
        ctx.json({
          data: cohort,
        })
      );
    }

    return res(ctx.status(404));
  }),
];
