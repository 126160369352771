import { SVGProps } from "react";
const SvgHorizontalDrag = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" {...props}>
    <path
      d="M14.73 14.723v4.76a.5.5 0 0 1-.501.5h-.451a.5.5 0 0 1-.5-.5v-4.76H8.496l.943.935a.731.731 0 0 1-1.03 1.038L6.232 14.52a.725.725 0 0 1 0-1.03l2.177-2.177a.725.725 0 0 1 1.023 1.023l-.936.936h4.782V8.482a.5.5 0 0 1 .5-.5h.451a.5.5 0 0 1 .5.5v4.79h4.782l-.943-.951a.726.726 0 0 1 1.023-1.023l2.177 2.177a.727.727 0 0 1 0 1.03l-2.177 2.177a.726.726 0 0 1-1.023-1.023l.943-.937h-4.782Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHorizontalDrag;
