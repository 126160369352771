import React, { SVGProps } from "react";

const SvgThreeDots: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="40" height="10" viewBox="0 0 130 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.0061 0C7.15996 0 0 7.15593 0 15.998C0 24.84 7.1519 32 16.0061 32C24.836 32 32.004 24.8521 32.004 15.998C32.004 7.15593 24.836 0 16.0061 0ZM65.4804 0C56.6545 0 49.4743 7.15189 49.4743 15.9939C49.4743 24.836 56.6464 31.996 65.4804 31.996C74.3144 31.996 81.4945 24.8481 81.4945 15.9939C81.4864 7.15593 74.3144 0 65.4804 0ZM113.309 0C104.467 0 97.2989 7.15593 97.2989 16.002C97.2989 24.836 104.455 31.996 113.309 31.996C122.135 31.996 129.303 24.8481 129.303 16.002C129.303 7.15593 122.135 0 113.309 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgThreeDots;
