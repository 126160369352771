import { SVGProps } from "react";
const SvgFilledChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none" width="24px" height="24px" {...props}>
    <path
      fill="currentColor"
      d="M30.35 20c.667 0 1.125.308 1.375.925s.142 1.158-.325 1.625L25 29a1.222 1.222 0 0 1-.5.325 1.91 1.91 0 0 1-.55.075c-.2 0-.383-.025-.55-.075a1.222 1.222 0 0 1-.5-.325l-6.4-6.45c-.467-.467-.575-1.008-.325-1.625.25-.617.708-.925 1.375-.925h12.8Z"
    />
  </svg>
);
export default SvgFilledChevronDown;
